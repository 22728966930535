export const CONTENT_PREFERENCES = [
  {
    id: 'EDGY_CONTENT',
    label: 'Include profanity and edgy content',
    option: 'I’m ok with profanity and edgy content',
    descriptions: [
      'Note: Bounty can allow profanity, but will never allow hateful or negative content',
    ],
  },
  {
    id: 'MAXIMIZE_VIRALITY',
    label: 'Maximize virality',
    option: 'I want to maximize virality',
    descriptions: [
      'Interested in creators with a higher follower count',
      'Creators may leverage more duets and trending sounds',
    ],
  },
  {
    id: 'MAXIMIZE_UGC',
    label: 'Maximize UGC',
    option: 'I want UGC I can clip and remix into high performing ads.',
    descriptions: [
      'Not focused on the follower count of creators',
      'Less focus on content following a specific creative brief',
    ],
  },
  {
    id: 'CONTENT_FOR_SPARK_ADS',
    label: 'Content for spark ads',
    option: 'I want posts I can use for Spark Ads',
    descriptions: ['More focus on content following a specific creative brief'],
  },
  {
    id: 'CONTENT_FOR_SOCIAL_MEDIA',
    label: 'Content for social media',
    option: 'I want content to repost on my own social media',
  },
  {
    id: 'CONTENT_FOR_SOCIAL_PROOF',
    label: 'Content for social proof',
    option: 'I want content I can use for social proof on my website',
  },
]
