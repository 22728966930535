/* eslint-disable no-restricted-globals */
/**
 * Making all of them promises to be a consistent interface with expo-secure-store. This is essentially
 * a web localstorage polyfill that's not secure at all but it's the best we have.
 */
const getItem = async (key: string) => {
  return localStorage.getItem(key)
}

const setItem = async (key: string, value: string) => {
  return localStorage.setItem(key, value)
}

const removeItem = async (key: string) => {
  return localStorage.removeItem(key)
}

export const SecureStore = {
  getItem,
  setItem,
  removeItem,
}
