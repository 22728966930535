export type VideoProps = {
  url: string
  autoplay?: boolean
  muted?: boolean
  loop?: boolean
  playsInline?: boolean
  poster?: string
}

export const Video = ({
  url,
  autoplay = true,
  muted = true,
  loop = false,
  playsInline = true,
  poster,
}: VideoProps) => {
  return (
    <video
      controls
      poster={poster}
      controlsList="nodownload"
      autoPlay={autoplay}
      muted={muted}
      playsInline={playsInline}
      loop={loop}
    >
      <source src={url} type="video/mp4" />
      Sorry, your browser doesn't support embedded videos.
    </video>
  )
}
