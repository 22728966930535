import mixpanel from 'mixpanel-browser'
import { useMemo } from 'react'

type Mixpanel = typeof mixpanel

export const initMixpanel = async (...params: Parameters<Mixpanel['init']>) => {
  return mixpanel.init(...params)
}

export const trackMixpanel = async (
  ...params: Parameters<Mixpanel['track']>
) => {
  return mixpanel.track(...params)
}

export const aliasMixpanel = async (
  ...params: Parameters<Mixpanel['alias']>
) => {
  return mixpanel.alias(...params)
}

export const identifyMixpanel = async (
  ...params: Parameters<Mixpanel['identify']>
) => {
  return mixpanel.identify(...params)
}

export const flushMixpanel = async () => {
  console.info('Flush not available for web')
}

export const setUserPropertyMixpanel = async (prop: string, to: any) => {
  return mixpanel.people.set(prop, to)
}

export const setUserPropertyOnceMixpanel = async (prop: string, to: any) => {
  return mixpanel.people.set_once(prop, to)
}

export const useAnalytics = () => {
  /**
   * This needs to be memo'ed to be extra safe if a user does not destructure. Example:
   *
   * const Foo = () => {
   * const [state, setState] = useState(false)
   * const analytics = useAnalytics()
   *
   *  useEffect(() => {
   *   console.log(
   *     'effect will be called every time because hook creates new object on rerender',
   *   )
   * }, [analytics])
   *
   *  return <button onClick={() => setState((x) => !x)}>foo</button>
   * }
   *
   * If you ALWAYS destructure you don't need the memo. But there's not a lint rule to enforce that so we memo
   * to be safe.
   */

  const bag = useMemo(
    () => ({
      init: initMixpanel,
      track: trackMixpanel,
      alias: aliasMixpanel,
      identify: identifyMixpanel,
      flush: flushMixpanel,
      setUserProperty: setUserPropertyMixpanel,
      setUserPropertyOnce: setUserPropertyOnceMixpanel,
      mixpanel,
    }),
    [],
  )

  return bag
}
