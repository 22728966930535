import { createTamagui } from 'tamagui'
import { shorthands } from '@tamagui/shorthands'

import { animations } from './animations'
import { fonts, media, tokens } from './foundations'
import { themes } from './themes'

export const config = createTamagui({
  animations,
  shouldAddPrefersColorThemes: true,
  themeClassNameOnRoot: true,
  shorthands,
  fonts: fonts,
  themes,
  tokens,
  media: media,
})
