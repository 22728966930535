/* eslint-disable react/forbid-elements */
import { Dialog, H2, YStack } from '@bounty/creators-design-system'
import { useEffect } from 'react'
import { createStateContext } from '../../../factory'
import { useHubspotForm } from 'next-hubspot'
import { captureMessage } from '../../../libs/sentry/sentry'

export const [SignUpModalProvider, useBrandSignUpModal] =
  createStateContext(false)

// Tamagui destroys children when modal is hidden so this needs to be called
// every time so we put it in a component
const FormCreator = () => {
  const { loaded, error, formCreated } = useHubspotForm({
    portalId: '24082817',
    formId: '0747ed24-0e28-4a32-84d5-278564162811',
    target: '#brand-sign-up',
  })

  useEffect(() => {
    if (loaded === false || formCreated === false) {
      captureMessage(
        `Brand tried to sign up to for a call but it was not ready. loaded = ${loaded} formCreated = ${formCreated} error = ${error}`,
      )
    }
  }, [loaded, formCreated, error])

  return null
}

export const BrandSignUpModal = () => {
  const [open, setOpen] = useBrandSignUpModal()

  return (
    <Dialog
      dialogContentProps={{ width: 500, maxWidth: 500 }}
      open={open}
      onOpenChange={setOpen}
    >
      <H2 size="$lg" mb="$4">
        Learn more about Bounty
      </H2>
      <FormCreator />
      <YStack id="brand-sign-up"></YStack>
    </Dialog>
  )
}
