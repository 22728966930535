import { GetProps, styled } from 'tamagui'
import { Text } from './text'

export const Blockquote = styled(Text, {
  name: 'Blockquote',
  tag: 'blockquote',
  borderLeftWidth: '$2',
  borderColor: '$primary.300',
  fontSize: '$xl',
  pl: '$4',
  mx: '$6',
})

export type BlockquoteProps = GetProps<typeof Blockquote>
