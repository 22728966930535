import { VideoProps } from '../../components/Video'
import { createStateContext } from '../../factory'

export const [VideoPlayerProvider, useVideoPlayerState] = createStateContext<{
  videoUrl: string | null
  poster: string | null
  /**
   * Set this if you'd like to make videos do things in specific cases. Be careful,
   * this can cause footguns!
   *
   * This is reset to empty object when a user closes a video
   *
   * Example: You want a video to play audio and then another video to be muted. You'll need
   * to be explicit on both.
   */
  videoConfig: Omit<Partial<VideoProps>, 'poster' | 'videoUrl'>
}>({
  videoUrl: null,
  poster: null,
  videoConfig: {},
})
