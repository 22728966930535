import { createTheme } from 'tamagui'
import { tokens } from '../foundations'
import { BaseTheme } from './light'

import { buildSemanticColorTokens } from './utils'

export const dark: BaseTheme = createTheme({
  background: tokens.color['gray.50.dark'],
  backgroundHover: tokens.color['gray.200.dark'],
  backgroundPress: tokens.color['gray.300.dark'],
  backgroundFocus: tokens.color['gray.400.dark'],
  borderColor: tokens.color['gray.300.dark'],
  borderColorHover: tokens.color['gray.500.dark'],
  borderColorFocus: tokens.color['gray.600.dark'],
  borderColorPress: tokens.color['gray.600.dark'],
  placeholderColor: tokens.color['gray.500.dark'],
  color: tokens.color['gray.800.dark'],
  colorHover: tokens.color['gray.600.dark'],
  colorPress: tokens.color['gray.900.dark'],
  colorFocus: tokens.color['gray.800.dark'],
  shadowColor: tokens.color['whiteAlpha.500.dark'],
  shadowColorHover: tokens.color['whiteAlpha.600.dark'],
  backgroundStrong: 'white',
  ...buildSemanticColorTokens({ mode: 'dark' }),
})
