import { ReactNode } from 'react'
import { YStack, YStackProps, XStack } from 'tamagui'
import { createStateContext } from '../factory/createStateContext'
import { ErrorIcon, InfoIcon, CheckCircleIcon, CloseIcon } from '../icons'
import { ColorScheme } from '../themes/utils'
import { Button, ButtonProps } from './Button'
import { IconButton, IconButtonProps } from './IconButton'
import { HeadingProps, Text, TextProps } from './text'

const [Context, useContext] = createStateContext<{ colorScheme: ColorScheme }>({
  colorScheme: 'primary',
})

export type AlertTitleProps = HeadingProps

export const AlertTitle = ({ children, ...rest }: AlertTitleProps) => {
  const [{ colorScheme }] = useContext()
  return (
    <Text
      size="$md"
      mb="$1"
      fontWeight={'$semibold'}
      color={`$${colorScheme}.900`}
      {...rest}
    >
      {children}
    </Text>
  )
}

export type AlertDescriptionProps = TextProps

export const AlertDescription = ({
  children,
  ...rest
}: AlertDescriptionProps) => {
  const [{ colorScheme }] = useContext()
  return (
    <Text size="$sm" color={`$${colorScheme}.900`} {...rest}>
      {children}
    </Text>
  )
}

export type AlertButtonProps = ButtonProps

export const AlertButton = ({ children, ...props }: AlertButtonProps) => {
  const [{ colorScheme }] = useContext()
  return (
    <Button marginTop="$3" size="$xs" colorScheme={colorScheme} {...props}>
      {children}
    </Button>
  )
}

export type AlertCloseButtonProps = Omit<IconButtonProps, 'icon'>

export const AlertCloseButton = ({
  children,
  ...props
}: AlertCloseButtonProps) => {
  const [{ colorScheme }] = useContext()
  return (
    <IconButton
      position="absolute"
      top={10}
      right={10}
      colorScheme={colorScheme}
      icon={CloseIcon}
      size="$xs"
      {...props}
    />
  )
}

export type AlertProps = YStackProps & {
  children: ReactNode
  status?: 'info' | 'warning' | 'loading' | 'neutral' | 'error' | 'success'
}

export const getIconForStatus = (status: AlertProps['status']) => {
  if (!status) throw new Error('status required')

  switch (status) {
    case 'info':
    case 'warning':
    case 'loading':
    case 'neutral':
      return InfoIcon
    case 'error':
      return ErrorIcon
    case 'success':
      return CheckCircleIcon

    default:
      throw new Error(status)
  }
}

export const getColorSchemeForStatus = (status: AlertProps['status']) => {
  if (!status) throw new Error('status required')

  switch (status) {
    case 'info':
      return 'primary'
    case 'warning':
      return 'warning'
    case 'loading':
    case 'neutral':
      return 'neutral'
    case 'error':
      return 'error'
    case 'success':
      return 'success'

    default:
      throw new Error(status)
  }
}

export const Alert = ({ children, status = 'info', ...rest }: AlertProps) => {
  const colorScheme = getColorSchemeForStatus(status)
  const Icon = getIconForStatus(status)

  return (
    <Context initialValue={{ colorScheme }}>
      <XStack
        px="$4"
        py="$3"
        backgroundColor={`$${colorScheme}.50`}
        borderColor={`$${colorScheme}.200`}
        borderWidth={1}
        borderRadius={'$3'}
        {...rest}
      >
        <XStack height={24} alignItems="center" flexShrink={0} mr="$2">
          <Icon color={`$${colorScheme}.500`} size={20} />
        </XStack>
        <YStack flexShrink={1}>{children}</YStack>
      </XStack>
    </Context>
  )
}
