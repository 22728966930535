import { createStateContext } from '../../factory'
import {
  SelectableBriefsQuery,
  UserBountyContentType,
} from '../../generated/backendGraphql'

export const [AcceptBountyProvider, useAcceptBounty] = createStateContext<{
  bountyContentType: UserBountyContentType
  selectedBrief: SelectableBriefsQuery['selectableBriefs'][number] | null
}>({
  bountyContentType: 'TIKTOK_VIDEO' as UserBountyContentType,
  selectedBrief: null,
})
