import config from '../tamagui.config'
import {
  TamaguiProvider,
  TamaguiProviderProps,
  isWeb,
} from '@bounty/creators-design-system'
import { IconContextProvider } from 'tamagui-phosphor-icons'
import { AuthProvider } from './auth'
import { ApolloProvider } from '@apollo/client'
import { client } from '../apollo/client'
import { AppState } from 'react-native'
import { SWRConfig } from 'swr'
import { PortableTextComponentsProvider } from '@portabletext/react'
import { buildPortableTextComponents } from '../components/buildPortableTextComponents'
import { VideoPlayerProvider } from './videoPlayer'
import { SafeAreaProvider } from '../hooks/useSafeAreaInsets/useSafeAreaInsets'
import { InviteProvider } from '../hooks/useInvite/useInvite'
import { NavigationProvider } from './navigation/navigationProvider'
import { AcceptBountyProvider } from '../screens/bounty-detail/useAcceptBounty'
import { WalletProvider } from '../screens/wallet/useWallet'
import { VideoPlayer } from '../components/VideoPlayer'
import { AuthedAnalytics } from '../components/AuthedAnalytics'
import { PushNotificationsListener } from '../hooks/usePushNotifications/PushNotificationRedirects'
import { GrowthBookProvider } from '@growthbook/growthbook-react'
import { growthBook } from '../libs/growthBook'

const portableTextComponents = buildPortableTextComponents()

/**
 * Providers that are universal to be invoked at the root of native and web
 */
export function UniversalProvider({
  children,
  fallback,
  ...rest
}: Omit<TamaguiProviderProps, 'config'> & {
  /** Used with SWR and getStaticPRops see: https://swr.vercel.app/docs/with-nextjs */
  fallback?: any
}) {
  return (
    <SafeAreaProvider>
      <SWRConfig
        value={{
          // There is a bug in the useSWR. If the fallback key is passed without a fallback
          // it blows up on native for some reason
          fallback: fallback ?? {},
          fetcher: (resource, init) =>
            fetch(resource, init).then((res) => res.json()),
          provider: () => new Map(),
          isVisible: () => {
            return true
          },
          initFocus(callback) {
            if (isWeb) return callback()

            let appState = AppState.currentState

            const onAppStateChange = (nextAppState: any) => {
              /* If it's resuming from background or inactive mode to active one */
              if (
                appState.match(/inactive|background/) &&
                nextAppState === 'active'
              ) {
                callback()
              }
              appState = nextAppState
            }

            // Subscribe to the app state change events
            const subscription = AppState.addEventListener(
              'change',
              onAppStateChange,
            )

            return () => {
              subscription.remove()
            }
          },
        }}
      >
        <TamaguiProvider
          config={config}
          disableInjectCSS
          defaultTheme="light"
          {...rest}
        >
          <IconContextProvider
            value={{ weight: 'regular', color: '$primary.500' }}
          >
            <ApolloProvider client={client}>
              <PortableTextComponentsProvider
                components={portableTextComponents}
              >
                <GrowthBookProvider growthbook={growthBook}>
                  <NavigationProvider>
                    <PushNotificationsListener />
                    <InviteProvider>
                      <VideoPlayerProvider>
                        <WalletProvider>
                          <AcceptBountyProvider>
                            <AuthProvider>
                              <AuthedAnalytics />
                              {children}
                            </AuthProvider>
                            <VideoPlayer />
                          </AcceptBountyProvider>
                        </WalletProvider>
                      </VideoPlayerProvider>
                    </InviteProvider>
                  </NavigationProvider>
                </GrowthBookProvider>
              </PortableTextComponentsProvider>
            </ApolloProvider>
          </IconContextProvider>
        </TamaguiProvider>
      </SWRConfig>
    </SafeAreaProvider>
  )
}
