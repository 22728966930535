export const UNITED_STATES_STATES = [
  {
    value: 'AL',
    name: 'Alabama',
  },
  {
    value: 'AK',
    name: 'Alaska',
  },
  {
    value: 'AS',
    name: 'American Samoa',
  },
  {
    value: 'AZ',
    name: 'Arizona',
  },
  {
    value: 'AR',
    name: 'Arkansas',
  },
  {
    value: 'CA',
    name: 'California',
  },
  {
    value: 'CO',
    name: 'Colorado',
  },
  {
    value: 'CT',
    name: 'Connecticut',
  },
  {
    value: 'DE',
    name: 'Delaware',
  },
  {
    value: 'FL',
    name: 'Florida',
  },
  {
    value: 'GA',
    name: 'Georgia',
  },
  {
    value: 'GU',
    name: 'Guam',
  },
  {
    value: 'HI',
    name: 'Hawaii',
  },
  {
    value: 'ID',
    name: 'Idaho',
  },
  {
    value: 'IL',
    name: 'Illinois',
  },
  {
    value: 'IN',
    name: 'Indiana',
  },
  {
    value: 'IA',
    name: 'Iowa',
  },
  {
    value: 'KS',
    name: 'Kansas',
  },
  {
    value: 'KY',
    name: 'Kentucky',
  },
  {
    value: 'LA',
    name: 'Louisiana',
  },
  {
    value: 'ME',
    name: 'Maine',
  },
  {
    value: 'MH',
    name: 'Marshall Islands',
  },
  {
    value: 'MD',
    name: 'Maryland',
  },
  {
    value: 'MA',
    name: 'Massachusetts',
  },
  {
    value: 'MI',
    name: 'Michigan',
  },
  {
    value: 'FM',
    name: 'Micronesia',
  },
  {
    value: 'MN',
    name: 'Minnesota',
  },
  {
    value: 'MS',
    name: 'Mississippi',
  },
  {
    value: 'MO',
    name: 'Missouri',
  },
  {
    value: 'MT',
    name: 'Montana',
  },
  {
    value: 'NE',
    name: 'Nebraska',
  },
  {
    value: 'NV',
    name: 'Nevada',
  },
  {
    value: 'NH',
    name: 'New Hampshire',
  },
  {
    value: 'NJ',
    name: 'New Jersey',
  },
  {
    value: 'NM',
    name: 'New Mexico',
  },
  {
    value: 'NY',
    name: 'New York',
  },
  {
    value: 'NC',
    name: 'North Carolina',
  },
  {
    value: 'ND',
    name: 'North Dakota',
  },
  {
    value: 'MP',
    name: 'Northern Mariana Islands',
  },
  {
    value: 'OH',
    name: 'Ohio',
  },
  {
    value: 'OK',
    name: 'Oklahoma',
  },
  {
    value: 'OR',
    name: 'Oregon',
  },
  {
    value: 'PW',
    name: 'Palau',
  },
  {
    value: 'PA',
    name: 'Pennsylvania',
  },
  {
    value: 'PR',
    name: 'Puerto Rico',
  },
  {
    value: 'RI',
    name: 'Rhode Island',
  },
  {
    value: 'SC',
    name: 'South Carolina',
  },
  {
    value: 'SD',
    name: 'South Dakota',
  },
  {
    value: 'TN',
    name: 'Tennessee',
  },
  {
    value: 'TX',
    name: 'Texas',
  },
  {
    value: 'VI',
    name: 'U.S. Virgin Islands',
  },
  {
    value: 'UT',
    name: 'Utah',
  },
  {
    value: 'VT',
    name: 'Vermont',
  },
  {
    value: 'VA',
    name: 'Virginia',
  },
  {
    value: 'WA',
    name: 'Washington',
  },
  {
    value: 'DC',
    name: 'Washington DC',
  },
  {
    value: 'WV',
    name: 'West Virginia',
  },
  {
    value: 'WI',
    name: 'Wisconsin',
  },
  {
    value: 'WY',
    name: 'Wyoming',
  },
  {
    value: 'AA',
    name: 'Armed Forces Americas',
  },
  {
    value: 'AE',
    name: 'Armed Forces Europe',
  },
  {
    value: 'AP',
    name: 'Armed Forces Pacific',
  },
] as const

export const AUSTRALIAN_TERRITORIES = [
  {
    value: 'ACT',
    name: 'Australian Capital Territory',
  },
  {
    value: 'NSW',
    name: 'New South Wales',
  },
  {
    value: 'NT',
    name: 'Northern Territory',
  },
  {
    value: 'QLD',
    name: 'Queensland',
  },
  {
    value: 'SA',
    name: 'South Australia',
  },
  {
    value: 'TAS',
    name: 'Tasmania',
  },
  {
    value: 'VIC',
    name: 'Victoria',
  },
  {
    value: 'WA',
    name: 'Western Australia',
  },
] as const

export const CANADIAN_PROVINCES = [
  {
    value: 'AB',
    name: 'Alberta',
  },
  {
    value: 'BC',
    name: 'British Columbia',
  },
  {
    value: 'MB',
    name: 'Manitoba',
  },
  {
    value: 'NB',
    name: 'New Brunswick',
  },
  {
    value: 'NL',
    name: 'Newfoundland and Labrador',
  },
  {
    value: 'NT',
    name: 'Northwest Territories',
  },
  {
    value: 'NS',
    name: 'Nova Scotia',
  },
  {
    value: 'NU',
    name: 'Nunavut',
  },
  {
    value: 'ON',
    name: 'Ontario',
  },
  {
    value: 'PE',
    name: 'Prince Edward Island',
  },
  {
    value: 'QC',
    name: 'Quebec',
  },
  {
    value: 'SK',
    name: 'Saskatchewan',
  },
  {
    value: 'YT',
    name: 'Yukon',
  },
] as const

export const SUPPORTED_COUNTRIES_FOR_GIFTS_MAPPING = [
  { value: 'US', name: 'United States' },
  { value: 'CA', name: 'Canada' },
]
