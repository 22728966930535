import { NextThemeProvider, useRootTheme } from '@tamagui/next-theme'
import Head from 'next/head'
import React, { useEffect, ReactElement, ReactNode } from 'react'
import type { SolitoAppProps } from 'solito'
import 'raf/polyfill'
import {
  DEPLOY_ENV,
  MIXPANEL_PROJECT_TOKEN,
  RUN_MOCKED_BACKEND,
  CLARITY_PROJECT_ID,
  GTAG_MEASUREMENT_ID,
} from '@bounty/creators-common/config/env'
import '@tamagui/core/reset.css'
import '../app.css'
// We are on web only here
// eslint-disable-next-line
import { initClarity, initGa4, setClarityUser } from '@bounty/web-analytics'
import { DefaultSeo } from 'next-seo'
import { Toaster, useAnalytics } from '@bounty/creators-design-system'
import { ErrorBoundary } from '../error'
import { useCreatorsRedirectShim } from '../useCreatorsRedirectShim'
import { NextPage } from 'next'
import { UniversalProvider } from '@bounty/creators-common/provider/UniversalProvider'
import { Zendesk } from '@bounty/creators-common/components/Zendesk'
import { useAuth } from '@bounty/creators-common/hooks'
import {
  BrandSignUpModal,
  SignUpModalProvider,
} from '@bounty/creators-common/screens/brands/components/SignUpModal'
import { HubspotProvider } from 'next-hubspot'
import { logger } from '@bounty/creators-common/utils/logger'
import { growthBook } from '@bounty/creators-common/libs/growthBook'
import ZohoSalesIQ from '@bounty/creators-common/components/ZohoChat'

export type NextPageWithLayout<P = any, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode
}

if (RUN_MOCKED_BACKEND) {
  await import('@bounty/creators-common/mocks/init')
}

function MyApp({ Component, pageProps }: SolitoAppProps) {
  useCreatorsRedirectShim()
  const getLayout = Component.getLayout ?? ((page) => page)

  useEffect(() => {
    // Load features from the GrowthBook API
    growthBook.loadFeatures({ autoRefresh: true })
  }, [])

  return (
    <>
      <Head>
        {DEPLOY_ENV === 'production' && (
          // This is gtm not ga
          // eslint-disable-next-line @next/next/next-script-for-ga
          <script
            dangerouslySetInnerHTML={{
              __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-T9925BT');`,
            }}
          />
        )}
        <link rel="icon" href="/favicon.png" />
      </Head>
      {DEPLOY_ENV === 'production' && (
        <noscript>
          <iframe
            title="gtm"
            src={`https://www.googletagmanager.com/ns.html?id=GTM-T9925BT`}
            height="0"
            width="0"
            style={{ display: 'none', visibility: 'hidden' }}
          ></iframe>
        </noscript>
      )}
      {/*
      // @ts-expect-error - I don't know how to type pageprops */}
      <AppProvider fallback={pageProps.fallback}>
        {getLayout(
          <ErrorBoundary>
            <Component {...pageProps} />
          </ErrorBoundary>,
          pageProps,
        )}
      </AppProvider>
    </>
  )
}

// Web only, not SSR friendly analytics
const Analytics = () => {
  const { init } = useAnalytics()
  useEffect(() => {
    if (['production', 'development'].includes(DEPLOY_ENV)) {
      init(MIXPANEL_PROJECT_TOKEN)
      initClarity({ isEnabled: true, projectId: CLARITY_PROJECT_ID })
      initGa4({ measurementId: GTAG_MEASUREMENT_ID })
    } else {
      init('local', { test: true })
    }
  }, [init])
  return null
}

const TrackNotificationAnalytics = () => {
  const { track, identify } = useAnalytics()
  // Use effect to run client side only
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const params: Record<string, any> = {}
    urlParams.forEach((value, key) => {
      params[key] = value
    })

    if (params.bnid) {
      if (params.userId) {
        identify(params.userId)
      }
      logger.log('Notification opened with id', params.bnid)
      track('Notification Opened by User', params)
    }
  }, [track, identify])

  return null
}

// Clarity is web only, not native so we need to call it special here
const AuthedWebAnalytics = () => {
  const [{ isAuthed, user }] = useAuth()

  useEffect(() => {
    if (
      isAuthed &&
      user &&
      ['production', 'development'].includes(DEPLOY_ENV)
    ) {
      setClarityUser(user.userId)
    }
  }, [isAuthed, user])

  return null
}

const InitZendesk = () => {
  const [{ isAuthed, user }] = useAuth()
  const widgetId =
    'siqf35259e9ce152a0e43c10fe4c04f3b49f2afdfed0c649a6f815c779c5e4736a9'
  return isAuthed && user ? (
    <ZohoSalesIQ widgetId={widgetId} userId={user.userId} />
  ) : null
}

function AppProvider({
  children,
  fallback,
}: {
  children: React.ReactNode
  fallback: any
}) {
  const [theme, setTheme] = useRootTheme()

  return (
    <NextThemeProvider
      enableSystem={false}
      forcedTheme="light"
      onChangeTheme={setTheme}
    >
      {/* UniversalProvider has blocking calls so we want this to be outside of that component to bootstrap these ASAP */}
      <Analytics />
      <TrackNotificationAnalytics />
      {/* Script is loaded after interactive but if there is perf prob need to do it only on brand pages */}
      <HubspotProvider>
        <SignUpModalProvider>
          <UniversalProvider
            disableRootThemeClass
            defaultTheme={theme}
            fallback={fallback}
          >
            <Toaster />
            <DefaultSeo
              title="Bounty | Get Paid to Post on TikTok and Instagram"
              openGraph={{
                type: 'website',
                locale: 'en_US',
                url: 'https://www.bounty.co/',
                siteName: 'Bounty',
                description: `Get paid for sharing product you love on TikTok and Instagram. It’s the easiest way to get paid as a creator.`,
                images: [
                  {
                    url: `https://${
                      DEPLOY_ENV === 'production' ? '' : 'develop.'
                    }www.bounty.co/bounty-social-share.jpg`,
                    alt: `Get paid for sharing product you love on TikTok and Instagram. It’s the easiest way to get paid as a creator.`,
                    height: 630,
                    width: 1200,
                    type: 'image/jpeg',
                  },
                ],
              }}
              twitter={{
                handle: '@trybounty',
                site: '@trybounty',
                cardType: 'summary_large_image',
              }}
            />
            <AuthedWebAnalytics />
            {(DEPLOY_ENV === 'production' || DEPLOY_ENV === 'development') && (
              <InitZendesk />
            )}
            {children}
            <BrandSignUpModal />
          </UniversalProvider>
        </SignUpModalProvider>
      </HubspotProvider>
    </NextThemeProvider>
  )
}

export default MyApp
