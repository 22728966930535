import { Button, ButtonProps } from './Button'
import {
  useLink,
  UseLinkProps,
  Link as LinkCore,
  LinkProps as LinkPropsCore,
} from 'solito/link'
import { Text, TextProps } from './text'
import { GestureResponderEvent, Linking } from 'react-native'
import { EventType, useHandleEvent } from '../hooks'
import { useCallback } from 'react'

// TODO: There's a fancy tamagui way of expanding all these props to real values
export type LinkProps = {
  flexGrow?: number
  onPress?: () => void
  event: EventType
} & LinkPropsCore

export const Link = ({
  flexGrow,
  onPress: onPressProp,
  event,
  ...props
}: LinkProps) => {
  const { handleEvent } = useHandleEvent()

  const onPress = useCallback<(event: GestureResponderEvent) => void>(
    (e) => {
      handleEvent(event)
      onPressProp?.()
    },
    [onPressProp, event, handleEvent],
  )

  return (
    <LinkCore
      viewProps={{
        style: { flexGrow },
      }}
      onClick={onPress}
      {...props}
    />
  )
}

export type LinkButtonProps = UseLinkProps & Omit<ButtonProps, 'debug'>

export const LinkButton = ({
  href,
  shallow,
  replace,
  experimental,
  as,
  scroll,
  ...props
}: LinkButtonProps) => {
  const linkProps = useLink({
    href,
    shallow,
    experimental,
    replace,
    as,
    scroll,
  })
  return (
    <Button
      {...props}
      {...linkProps}
      onPress={(e) => {
        props?.onPress?.(e)

        if (typeof href !== 'string') return

        if (href.startsWith('/')) {
          linkProps.onPress()
        } else {
          Linking.canOpenURL(href).then(() => Linking.openURL(href))
        }
      }}
    />
  )
}

export type InlineLinkProps = UseLinkProps &
  TextProps & {
    event: EventType
  }

export const InlineLink = ({
  href,
  shallow,
  replace,
  experimental,
  as,
  scroll,
  event,
  ...props
}: InlineLinkProps) => {
  const { handleEvent, produceTestIdFromEvent } = useHandleEvent()
  const linkProps = useLink({
    href,
    shallow,
    experimental,
    replace,
    as,
    scroll,
  })

  return (
    <Text
      textDecorationLine="underline"
      cursor="pointer"
      accessibilityRole="link"
      data-testid={produceTestIdFromEvent(event)}
      {...props}
      onPress={(e) => {
        props?.onPress?.(e)
        handleEvent(event)

        if (typeof href !== 'string') return

        if (href.startsWith('/')) {
          linkProps.onPress()
        } else {
          Linking.canOpenURL(href).then(() => Linking.openURL(href))
        }
      }}
    />
  )
}
