import { trackMixpanel } from '@bounty/creators-design-system'
import { GrowthBook } from '@growthbook/growthbook-react'
import { DEPLOY_ENV, GROWTH_BOOK_CLIENT_KEY } from '../config/env'
import { logger } from '../utils/logger'

const isEnabled = DEPLOY_ENV !== 'local'

if (!isEnabled) {
  logger.log(
    'Growth Book feature flagging is disabled for local dev. To test out your flags hard code isEnabled to true but do not commit it!',
  )
}

/**
 * Docs: https://docs.growthbook.io/lib/react
 */
export const growthBook = new GrowthBook({
  apiHost: 'https://cdn.growthbook.io',
  clientKey: GROWTH_BOOK_CLIENT_KEY,
  enableDevMode: DEPLOY_ENV === 'production' ? false : true,

  enabled: isEnabled,

  trackingCallback: (experiment, result) => {
    logger.log('Viewed Experiment', {
      experimentId: experiment.key,
    })

    trackMixpanel('$experiment_started', {
      'Experiment name': experiment.key,
      'Variant name': result.variationId,
      $source: 'growthbook',
    })
  },
})
