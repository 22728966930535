import { Fragment } from 'react'
import { EdgeInsets } from 'react-native-safe-area-context'

export const SafeAreaProvider = Fragment
export const useSafeAreaInsets = (): EdgeInsets => {
  return {
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
  }
}
