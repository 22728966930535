import { GetProps, styled } from '@tamagui/core'
import { Text } from './Text'

export const Heading = styled(Text, {
  tag: 'span',
  name: 'Heading',
  accessibilityRole: 'header',
  fontFamily: '$heading',
  margin: 0,
  defaultVariants: {
    size: '$md',
  },
})

export type HeadingProps = GetProps<typeof Heading>

export const H1 = styled(Heading, {
  name: 'H1',
  tag: 'h1',
  size: '$5xl',
})

export type H1Props = GetProps<typeof H1>

export const H2 = styled(Heading, {
  name: 'H2',
  tag: 'h2',
  size: '$4xl',
})

export type H2Props = GetProps<typeof H2>

export const H3 = styled(Heading, {
  name: 'H3',
  tag: 'h3',
  size: '$3xl',
})

export type H3Props = GetProps<typeof H3>

export const H4 = styled(Heading, {
  name: 'H4',
  tag: 'h4',
  size: '$2xl',
})

export type H4Props = GetProps<typeof H4>

export const H5 = styled(Heading, {
  name: 'H5',
  tag: 'h5',
  size: '$xl',
})

export type H5Props = GetProps<typeof H5>

export const H6 = styled(Heading, {
  name: 'H6',
  tag: 'h6',
  size: '$md',
})

export type H6Props = GetProps<typeof H6>
