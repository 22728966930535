import {
  Stack,
  StackProps,
  styled,
  GetProps,
  themeable,
  TamaguiElement,
  VariantSpreadFunction,
  ColorTokens,
} from '@tamagui/core'
import { Text } from './text'
import { ComponentType, forwardRef, ReactNode, useCallback } from 'react'
import { ColorScheme } from '../themes/utils'
import { GestureResponderEvent } from 'react-native'
import { Spinner, XStack } from 'tamagui'
import { IconProps } from 'tamagui-phosphor-icons'
import { EventType, useHandleEvent } from '../hooks/useHandleEvent'

export type ButtonVariant = 'solid' | 'outline' | 'ghost' | 'link'
export type ButtonSize = '$xs' | '$sm' | '$md' | '$lg'
export type ButtonColorScheme = ColorScheme

type VariantProps = {
  variant?: ButtonVariant
  size?: ButtonSize
  colorScheme?: ButtonColorScheme
}

const getButtonColorScheme: VariantSpreadFunction<
  GetProps<typeof Stack> & VariantProps,
  ButtonColorScheme
> = (val = 'primary', { fonts, theme, props }) => {
  const { variant } = props
  switch (variant) {
    case 'solid':
      return {
        backgroundColor: `$${val}.500`,
        pressStyle: {
          backgroundColor: `$${val}.700`,
        },

        hoverStyle: {
          backgroundColor: `$${val}.600`,
        },

        focusStyle: {
          backgroundColor: `$${val}.700`,
        },
      }
    case 'link':
      return {}
    case 'outline':
      return {
        borderColor: `$${val}.500`,
        pressStyle: {
          backgroundColor: `$${val}.100`,
        },

        hoverStyle: {
          backgroundColor: `$${val}.50`,
        },

        focusStyle: {
          backgroundColor: `$${val}.100`,
        },
      }
    case 'ghost':
      return {
        pressStyle: {
          backgroundColor: `$${val}.100`,
        },

        hoverStyle: {
          backgroundColor: `$${val}.50`,
        },

        focusStyle: {
          backgroundColor: `$${val}.100`,
        },
      }
    default:
      throw new Error(variant)
  }
}

const getIsDisabledColorScheme: VariantSpreadFunction<
  GetProps<typeof Stack> & VariantProps,
  boolean
> = (val = false, { fonts, theme, props }) => {
  return val
    ? {
        backgroundColor: '$neutral.400',
        color: '$neutral.400',
        pointerEvents: 'none',
      }
    : {}
}

const getButtonVariant: VariantSpreadFunction<
  GetProps<typeof Stack> & VariantProps,
  ButtonVariant
> = (val = 'solid', { fonts, theme, props }) => {
  switch (val) {
    case 'solid':
      return {
        borderColor: 'transparent',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'nowrap',
        flexDirection: 'row',
        borderRadius: '$2',
      }
    case 'link':
      return {
        padding: '$0',
        height: 'auto',
        // borderColor: 'transparent',
        // justifyContent: 'center',
        // alignItems: 'center',
        // flexWrap: 'nowrap',
        // flexDirection: 'row',
        // borderRadius: '$2',
      }
    case 'outline':
      return {
        borderWidth: 1,
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'nowrap',
        flexDirection: 'row',
        borderRadius: '$2',
      }
    case 'ghost':
      return {
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'nowrap',
        flexDirection: 'row',
        borderRadius: '$2',
      }
    default:
      throw new Error(val)
  }
}

const ButtonFrame = styled(Stack, {
  name: 'Button',
  tag: 'button',

  // if we wanted this only when pressable = true, we'd need to merge variants?
  cursor: 'pointer',
  // @ts-expect-error - Web only prop
  type: 'button',

  variants: {
    size: {
      $xs: {
        height: '$6',
        paddingHorizontal: '$2',
      },
      $sm: {
        height: '$8',
        paddingHorizontal: '$3',
      },
      $md: {
        height: '$10',
        paddingHorizontal: '$3',
      },
      $lg: {
        height: '$12',
        paddingHorizontal: '$4',
      },
    },
    colorScheme: getButtonColorScheme,
    variant: getButtonVariant,

    // active: {
    //   true: {
    //     hoverStyle: {
    //       backgroundColor: '$background',
    //     },
    //   },
    // },

    isDisabled: getIsDisabledColorScheme,
  } as const,
})

const getButtonTextVariant: VariantSpreadFunction<
  GetProps<typeof Text> & Omit<VariantProps, 'size'>,
  ButtonVariant
> = (val = 'solid', { fonts, theme, props }) => {
  switch (val) {
    case 'solid':
      return {}
    case 'link':
      return {
        focusStyle: {
          textDecorationLine: 'underline',
        },
      }
    case 'outline':
      return {
        borderColor: `$${props.colorScheme}.500`,
      }
    case 'ghost':
      return {}
    default:
      throw new Error(val)
  }
}

const getButtonTextColorScheme: VariantSpreadFunction<
  GetProps<typeof Text> & Omit<VariantProps, 'size'>,
  ButtonColorScheme
> = (val = 'primary', { fonts, theme, props }) => {
  const { variant } = props
  switch (variant) {
    case 'solid':
      return {
        color: 'white',
      }
    case 'link':
      return {
        color: val === 'neutral' ? `$${val}.900` : `$${val}.500`,
      }
    case 'outline':
      return {
        color: val === 'neutral' ? `$${val}.900` : `$${val}.500`,
      }
    case 'ghost':
      return {
        color: val === 'neutral' ? `$${val}.900` : `$${val}.500`,
      }
    default:
      throw new Error(variant)
  }
}

const ButtonText = styled(Text, {
  name: 'ButtonText',
  fontWeight: '$semibold',
  userSelect: 'none',
  cursor: 'pointer',
  // flexGrow 1 leads to inconsistent native style where text pushes to start of view
  flexGrow: 0,
  flexShrink: 1,
  ellipse: true,
  variants: {
    colorScheme: getButtonTextColorScheme,
    variant: getButtonTextVariant,
  } as const,
})

/** Abstracted so that icon buttons can inherit */
const textColorForColorScheme = (
  val: ColorScheme,
  variant?: ButtonVariant,
): { color: ColorTokens } => {
  switch (variant) {
    case 'solid':
      return {
        color: '$neutral.50',
      } as const
    case 'link':
      return {
        color: val === 'neutral' ? `$${val}.900` : `$${val}.500`,
      } as const
    case 'outline':
      return {
        color: val === 'neutral' ? `$${val}.900` : `$${val}.500`,
      } as const
    case 'ghost':
      return {
        color: val === 'neutral' ? `$${val}.900` : `$${val}.500`,
      } as const
    default:
      throw new Error(variant)
  }
}

const getButtonLoaderColorScheme: VariantSpreadFunction<
  GetProps<typeof Spinner> & Omit<VariantProps, 'size'>,
  ButtonColorScheme
> = (val = 'primary', { fonts, theme, props }) => {
  const { variant } = props
  return textColorForColorScheme(val, variant)
}

const getButtonLoaderVariant: VariantSpreadFunction<
  GetProps<typeof Spinner> & Omit<VariantProps, 'size'>,
  ButtonVariant
> = (val = 'solid', { fonts, theme, props }) => {
  return {}
}

const ButtonLoader = styled(Spinner, {
  name: 'ButtonLoader',
  position: 'absolute',
  color: 'white',
  variants: {
    colorScheme: getButtonLoaderColorScheme,
    variant: getButtonLoaderVariant,
  } as const,
})

export type ButtonProps = GetProps<typeof ButtonFrame> &
  StackProps & {
    buttonTextProps?: GetProps<typeof ButtonText>
    iconRight?: ComponentType<IconProps> | null
    iconLeft?: ComponentType<IconProps> | null
    iconPropsRight?: IconProps
    iconPropsLeft?: IconProps
    /**
     * Event to send to analytics services. Please name them title cast in the following format:
     * Title Case in object action syntax
     *
     * @example Request Spark Code Clicked
     *
     * @docs https://segment.com/academy/collecting-data/naming-conventions-for-clean-data/
     */
    event: EventType
    isLoading?: boolean
    /**
     * Render a component inside of the button without using a wrapping text component
     */
    render?: ReactNode
    children?: ReactNode
  }

const getIconSizeFromButtonSize = (buttonSize: ButtonSize) => {
  switch (buttonSize) {
    case '$xs':
      return 14
    case '$sm':
      return 16
    case '$md':
      return 18
    case '$lg':
      return 20

    default:
      throw new Error(buttonSize)
  }
}

const ButtonComponent = forwardRef<TamaguiElement, ButtonProps>(function Button(
  {
    children,
    size = '$md',
    colorScheme = 'primary',
    variant = 'solid',
    isDisabled,
    buttonTextProps,
    event,
    onPress: onPressProp,
    iconRight: IconRight,
    iconLeft: IconLeft,
    iconPropsRight,
    iconPropsLeft,
    isLoading,
    render,
    ...props
  },
  ref,
) {
  const { handleEvent, produceTestIdFromEvent } = useHandleEvent()

  // This automatically creates a test id based off of the name of the first event found to
  // make it easier to write tests quickly. You can override this value if there are issues.

  const onPress = useCallback<(event: GestureResponderEvent) => void>(
    (e) => {
      handleEvent(event)
      onPressProp?.(e)
    },
    [onPressProp, event, handleEvent],
  )
  const colorConfig = textColorForColorScheme(colorScheme, variant)
  const iconSize = getIconSizeFromButtonSize(size)

  return (
    // @ts-expect-error "children" should be in props
    <ButtonFrame
      size={size}
      colorScheme={colorScheme}
      variant={variant}
      isDisabled={isDisabled || isLoading}
      onPress={onPress}
      data-testid={produceTestIdFromEvent(event)}
      {...props}
      ref={ref}
    >
      {IconLeft && (
        <XStack mr="$2" flexShrink={0}>
          <IconLeft
            size={iconSize}
            color={colorConfig.color}
            {...iconPropsLeft}
          />
        </XStack>
      )}
      {render ? (
        render
      ) : (
        <ButtonText
          size={size}
          colorScheme={colorScheme}
          variant={variant}
          display="flex"
          alignItems="center"
          numberOfLines={1}
          {...(isLoading && { color: 'transparent' })}
          {...buttonTextProps}
          // isDisabled={isDisabled}
        >
          {children}
        </ButtonText>
      )}
      {isLoading && (
        <ButtonLoader variant={variant} colorScheme={colorScheme} />
      )}
      {IconRight && (
        <XStack ml="$2" flexShrink={0}>
          <IconRight
            size={iconSize}
            color={colorConfig.color}
            {...iconPropsRight}
          />
        </XStack>
      )}
    </ButtonFrame>
  )
})

export const buttonStaticConfig = {
  inlineProps: new Set([
    // text props go here (can't really optimize them, but we never fully extract button anyway)
    // may be able to remove this entirely, as the compiler / runtime have gotten better
    'color',
    'fontWeight',
    'fontSize',
    'fontFamily',
    'letterSpacing',
    'textAlign',
  ]),
}

export const Button = ButtonFrame.extractable(
  themeable(ButtonComponent, ButtonFrame.staticConfig),
  buttonStaticConfig,
)
