var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import { SENTRY_DSN, DEPLOY_ENV } from '@bounty/creators-common/config/env'

if (['production', 'development'].includes(DEPLOY_ENV)) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: DEPLOY_ENV,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.5,
    ignoreErrors: [
      // These errors are from analytics (gtag, mixpanel) that are getting blocked by the user's browser
      'Failed to fetch',
      'TypeError: Failed to fetch',
      'Load failed',
      'TypeError: Load failed',
    ],
  })
}
