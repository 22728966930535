export type StoreCategoryParent = {
  label: string
  slug: string
  hasChildren: boolean
  value: ParentStoreCategoryValue
}

export type StoreCategory = {
  label: string
  labelLong: string
  slug: string
  value: StoreCategoryValue
  parent: string
}

export enum ParentStoreCategoryValue {
  WOMENS = 'womens',
  MENS = 'mens',
  GENDER_NEUTRAL = 'gender_neutral',
  BABIES_AND_KIDS = 'baby_and_kids',
  HOBBY = 'hobby',
  BEAUTY = 'beauty',
  WELLNESS = 'wellness',
  HOME = 'home',
  SPORTS_AND_OUTDOORS = 'sports_and_outdoors',
  ELECTRONICS = 'electronics',
  PETS = 'pets',
  ENTERTAINMENT = 'entertainment',
  AUTOMOTIVE = 'automotive',
}

export const STORE_CATEGORIES_PARENTS: StoreCategoryParent[] = [
  {
    label: `Women's`,
    slug: 'womens',
    hasChildren: true,
    value: ParentStoreCategoryValue.WOMENS,
  },
  {
    label: `Men's`,
    slug: 'mens',
    hasChildren: true,
    value: ParentStoreCategoryValue.MENS,
  },
  {
    label: 'Gender Neutral',
    slug: 'gender-neutral',
    hasChildren: false,
    value: ParentStoreCategoryValue.GENDER_NEUTRAL,
  },
  {
    label: 'Babies and Kids',
    slug: 'babies-and-kids',
    hasChildren: true,
    value: ParentStoreCategoryValue.BABIES_AND_KIDS,
  },
  {
    label: 'Hobby',
    slug: 'hobby',
    hasChildren: true,
    value: ParentStoreCategoryValue.HOBBY,
  },
  {
    label: 'Beauty',
    slug: 'beauty',
    hasChildren: true,
    value: ParentStoreCategoryValue.BEAUTY,
  },
  {
    label: 'Wellness',
    slug: 'wellness',
    hasChildren: true,
    value: ParentStoreCategoryValue.WELLNESS,
  },
  {
    label: 'Home',
    slug: 'home',
    hasChildren: true,
    value: ParentStoreCategoryValue.HOME,
  },
  {
    label: 'Sports and Outdoors',
    slug: 'sports-and-outdoors',
    hasChildren: true,
    value: ParentStoreCategoryValue.SPORTS_AND_OUTDOORS,
  },
  {
    label: 'Electronics',
    slug: 'electronics',
    hasChildren: true,
    value: ParentStoreCategoryValue.ELECTRONICS,
  },
  {
    label: 'Pets',
    slug: 'pets',
    hasChildren: true,
    value: ParentStoreCategoryValue.PETS,
  },
  {
    label: 'Entertainment',
    slug: 'entertainment',
    hasChildren: true,
    value: ParentStoreCategoryValue.ENTERTAINMENT,
  },
  {
    label: 'Automotive',
    slug: 'automotive',
    hasChildren: false,
    value: ParentStoreCategoryValue.AUTOMOTIVE,
  },
]

export enum StoreCategoryValue {
  WOMENS_ACTIVEWEAR = 'womens_activewear',
  WOMENS_CLOTHING = 'womens_clothing',
  WOMENS_JEWELRY_AND_ACCESSORIES = 'womens_jewelry_and_accessories',
  WOMENS_SHOES = 'womens_shoes',
  WOMENS_SWIMWEAR = 'womens_swimwear',
  MENS_ACTIVEWEAR = 'mens_activewear',
  MENS_CLOTHING = 'mens_clothing',
  MENS_JEWELRY_AND_ACCESSORIES = 'mens_jewelry_and_accessories',
  MENS_SHOES = 'mens_shoes',
  GENDER_NEUTRAL = 'gender_neutral',
  BABY_GEAR = 'baby_gear',
  BABY_CLOTHING = 'baby_clothing',
  TOYS = 'toys',
  BOARD_GAMES = 'board_games',
  ARTS_AND_CRAFTS = 'arts_and_crafts',
  PHOTOGRAPHY = 'photography',
  CONSUMER_ELECTRONICS = 'consumer_electronics',
  COSMETICS = 'cosmetics',
  HAIR = 'hair',
  PERSONAL_CARE = 'personal_care',
  NAIL_PRODUCTS = 'nail_products',
  HEALTH = 'health',
  CBD = 'cbd',
  FITNESS = 'fitness',
  VITAMINS_AND_SUPPLEMENTS = 'vitamins_and_supplements',
  ENERGY = 'energy',
  GARDEN_AND_PATIO = 'garden_and_patio',
  BED_AND_BATH = 'bed_and_bath',
  FURNITURE_AND_DECOR = 'furniture_and_decor',
  KITCHEN = 'kitchen',
  FOOD_AND_BEVERAGE = 'food_and_beverage',
  HOME_IMPROVEMENT = 'home_improvement',
  SEASONAL = 'seasonal',
  OFFICE = 'office',
  OUTDOOR = 'outdoor',
  SPORTING_GOODS = 'sporting_goods',
  SPORTS_APPAREL = 'sports_apparel',
  SPORTS_ACCESSORIES = 'sports_accessories',
  COMPUTERS_AND_TABLETS = 'computers_and_tablets',
  PHONES_AND_SMARTWATCHES = 'phones_and_smartwatches',
  SPEAKERS_AND_AUDIO = 'speakers_and_audio',
  GAMING = 'gaming',
  PETS = 'pets',
  CATS = 'cats',
  DOGS = 'dogs',
  BOOKS = 'books',
  MOVIES = 'movies',
  MUSIC = 'music',
  COMICS = 'comics',
  AUTOMOTIVE = 'automotive',
}

export const STORE_CATEGORIES: StoreCategory[] = [
  {
    label: 'Activewear',
    labelLong: `Women's Activewear`,
    slug: 'activewear',
    value: StoreCategoryValue.WOMENS_ACTIVEWEAR,
    parent: ParentStoreCategoryValue.WOMENS,
  },
  {
    label: 'Clothing',
    labelLong: `Women's Clothing`,
    slug: 'clothing',
    value: StoreCategoryValue.WOMENS_CLOTHING,
    parent: ParentStoreCategoryValue.WOMENS,
  },
  {
    label: 'Jewelry and Accessories',
    labelLong: `Women's Jewelry and Accessories`,
    slug: 'jewelry-and-accessories',
    value: StoreCategoryValue.WOMENS_JEWELRY_AND_ACCESSORIES,
    parent: ParentStoreCategoryValue.WOMENS,
  },
  {
    label: 'Shoes',
    labelLong: `Women's Shoes`,
    slug: 'shoes',
    value: StoreCategoryValue.WOMENS_SHOES,
    parent: ParentStoreCategoryValue.WOMENS,
  },
  {
    label: 'Swimwear',
    labelLong: `Women's Swimwear`,
    slug: 'swimwear',
    value: StoreCategoryValue.WOMENS_SWIMWEAR,
    parent: ParentStoreCategoryValue.WOMENS,
  },
  {
    label: 'Activewear',
    labelLong: `Men's Activewear`,
    slug: 'activewear',
    value: StoreCategoryValue.MENS_ACTIVEWEAR,
    parent: ParentStoreCategoryValue.MENS,
  },
  {
    label: 'Clothing',
    labelLong: `Men's Clothing`,
    slug: 'clothing',
    value: StoreCategoryValue.MENS_CLOTHING,
    parent: ParentStoreCategoryValue.MENS,
  },
  {
    label: 'Jewelry and Accessories',
    labelLong: `Men's Jewelry and Accessories`,
    slug: 'jewelry-and-accessories',
    value: StoreCategoryValue.MENS_JEWELRY_AND_ACCESSORIES,
    parent: ParentStoreCategoryValue.MENS,
  },
  {
    label: 'Shoes',
    labelLong: `Men's Shoes`,
    slug: 'shoes',
    value: StoreCategoryValue.MENS_SHOES,
    parent: ParentStoreCategoryValue.MENS,
  },
  {
    label: 'Gender Neutral',
    labelLong: 'Gender Neutral',
    slug: 'gender-neutral',
    value: StoreCategoryValue.GENDER_NEUTRAL,
    parent: ParentStoreCategoryValue.GENDER_NEUTRAL,
  },
  {
    label: 'Baby Gear',
    labelLong: 'Baby Gear',
    slug: 'baby-gear',
    value: StoreCategoryValue.BABY_GEAR,
    parent: ParentStoreCategoryValue.BABIES_AND_KIDS,
  },
  {
    label: 'Clothing',
    labelLong: 'Clothing',
    slug: 'clothing',
    value: StoreCategoryValue.BABY_CLOTHING,
    parent: ParentStoreCategoryValue.BABIES_AND_KIDS,
  },
  {
    label: 'Toys',
    labelLong: 'Toys',
    slug: 'toys',
    value: StoreCategoryValue.TOYS,
    parent: ParentStoreCategoryValue.HOBBY,
  },
  {
    label: 'Board Games',
    labelLong: 'Board Games',
    slug: 'board-games',
    value: StoreCategoryValue.BOARD_GAMES,
    parent: ParentStoreCategoryValue.HOBBY,
  },
  {
    label: 'Arts and Crafts',
    labelLong: 'Arts and Crafts',
    slug: 'arts-and-crafts',
    value: StoreCategoryValue.ARTS_AND_CRAFTS,
    parent: ParentStoreCategoryValue.HOBBY,
  },
  {
    label: 'Photography',
    labelLong: 'Photography',
    slug: 'photography',
    value: StoreCategoryValue.PHOTOGRAPHY,
    parent: ParentStoreCategoryValue.HOBBY,
  },
  {
    label: 'Cosmetics',
    labelLong: 'Cosmetics',
    slug: 'cosmetics',
    value: StoreCategoryValue.COSMETICS,
    parent: ParentStoreCategoryValue.BEAUTY,
  },
  {
    label: 'Hair',
    labelLong: 'Hair',
    slug: 'hair',
    value: StoreCategoryValue.HAIR,
    parent: ParentStoreCategoryValue.BEAUTY,
  },
  {
    label: 'Personal Care',
    labelLong: 'Personal Care',
    slug: 'personal-care',
    value: StoreCategoryValue.PERSONAL_CARE,
    parent: ParentStoreCategoryValue.BEAUTY,
  },
  {
    label: 'Nail Products',
    labelLong: 'Nail Products',
    slug: 'nail-products',
    value: StoreCategoryValue.NAIL_PRODUCTS,
    parent: ParentStoreCategoryValue.BEAUTY,
  },
  {
    label: 'Health',
    labelLong: 'Health',
    slug: 'health',
    value: StoreCategoryValue.HEALTH,
    parent: ParentStoreCategoryValue.WELLNESS,
  },
  {
    label: 'CBD',
    labelLong: 'CBD',
    slug: 'cbd',
    value: StoreCategoryValue.CBD,
    parent: ParentStoreCategoryValue.WELLNESS,
  },
  {
    label: 'Fitness',
    labelLong: 'Fitness',
    slug: 'fitness',
    value: StoreCategoryValue.FITNESS,
    parent: ParentStoreCategoryValue.WELLNESS,
  },
  {
    label: 'Vitamins and Supplements',
    labelLong: 'Vitamins and Supplements',
    slug: 'vitamins-and-supplements',
    value: StoreCategoryValue.VITAMINS_AND_SUPPLEMENTS,
    parent: ParentStoreCategoryValue.WELLNESS,
  },
  {
    label: 'Energy',
    labelLong: 'Energy',
    slug: 'energy',
    value: StoreCategoryValue.ENERGY,
    parent: ParentStoreCategoryValue.WELLNESS,
  },
  {
    label: 'Home and Garden',
    labelLong: 'Home and Garden',
    slug: 'home-and-garden',
    value: StoreCategoryValue.GARDEN_AND_PATIO,
    parent: ParentStoreCategoryValue.HOME,
  },
  {
    label: 'Bed and Bath',
    labelLong: 'Bed and Bath',
    slug: 'bed-and-bath',
    value: StoreCategoryValue.BED_AND_BATH,
    parent: ParentStoreCategoryValue.HOME,
  },
  {
    label: 'Furniture and Decor',
    labelLong: 'Furniture and Decor',
    slug: 'furniture-and-decor',
    value: StoreCategoryValue.FURNITURE_AND_DECOR,
    parent: ParentStoreCategoryValue.HOME,
  },
  {
    label: 'Kitchen',
    labelLong: 'Kitchen',
    slug: 'kitchen',
    value: StoreCategoryValue.KITCHEN,
    parent: ParentStoreCategoryValue.HOME,
  },
  {
    label: 'Food and Beverage',
    labelLong: 'Food and Beverage',
    slug: 'food-and-beverage',
    value: StoreCategoryValue.FOOD_AND_BEVERAGE,
    parent: ParentStoreCategoryValue.HOME,
  },
  {
    label: 'Home Improvement',
    labelLong: 'Home Improvement',
    slug: 'home-improvement',
    value: StoreCategoryValue.HOME_IMPROVEMENT,
    parent: ParentStoreCategoryValue.HOME,
  },
  {
    label: 'Seasonal',
    labelLong: 'Seasonal',
    slug: 'seasonal',
    value: StoreCategoryValue.SEASONAL,
    parent: ParentStoreCategoryValue.HOME,
  },
  {
    label: 'Office',
    labelLong: 'Office',
    slug: 'office',
    value: StoreCategoryValue.OFFICE,
    parent: ParentStoreCategoryValue.HOME,
  },
  {
    label: 'Outdoor',
    labelLong: 'Outdoor',
    slug: 'outdoor',
    value: StoreCategoryValue.OUTDOOR,
    parent: ParentStoreCategoryValue.SPORTS_AND_OUTDOORS,
  },
  {
    label: 'Sporting Goods',
    labelLong: 'Sporting Goods',
    slug: 'sporting-goods',
    value: StoreCategoryValue.SPORTING_GOODS,
    parent: ParentStoreCategoryValue.SPORTS_AND_OUTDOORS,
  },
  {
    label: 'Sports Apparel',
    labelLong: 'Sports Apparel',
    slug: 'sports-apparel',
    value: StoreCategoryValue.SPORTS_APPAREL,
    parent: ParentStoreCategoryValue.SPORTS_AND_OUTDOORS,
  },
  {
    label: 'Sports Accessories',
    labelLong: 'Sports Accessories',
    slug: 'sports-accessories',
    value: StoreCategoryValue.SPORTS_ACCESSORIES,
    parent: ParentStoreCategoryValue.SPORTS_AND_OUTDOORS,
  },
  {
    label: 'Computers and Tablets',
    labelLong: 'Computers and Tablets',
    slug: 'computers-and-tablets',
    value: StoreCategoryValue.COMPUTERS_AND_TABLETS,
    parent: ParentStoreCategoryValue.ELECTRONICS,
  },
  {
    label: 'Phones and Smartwatches',
    labelLong: 'Phones and Smartwatches',
    slug: 'phones-and-smartwatches',
    value: StoreCategoryValue.PHONES_AND_SMARTWATCHES,
    parent: ParentStoreCategoryValue.ELECTRONICS,
  },
  {
    label: 'Speakers and Audio',
    labelLong: 'Speakers and Audio',
    slug: 'speakers-and-audio',
    value: StoreCategoryValue.SPEAKERS_AND_AUDIO,
    parent: ParentStoreCategoryValue.ELECTRONICS,
  },
  {
    label: 'Gaming',
    labelLong: 'Gaming',
    slug: 'gaming',
    value: StoreCategoryValue.GAMING,
    parent: ParentStoreCategoryValue.ELECTRONICS,
  },
  {
    label: 'Cats',
    labelLong: 'Cats',
    slug: 'cats',
    value: StoreCategoryValue.CATS,
    parent: ParentStoreCategoryValue.PETS,
  },
  {
    label: 'Dogs',
    labelLong: 'Dogs',
    slug: 'dogs',
    value: StoreCategoryValue.DOGS,
    parent: ParentStoreCategoryValue.PETS,
  },
  {
    label: 'Other Pets',
    labelLong: 'Other Pets',
    slug: 'other-pets',
    value: StoreCategoryValue.PETS,
    parent: ParentStoreCategoryValue.PETS,
  },
  {
    label: 'Books',
    labelLong: 'Books',
    slug: 'books',
    value: StoreCategoryValue.BOOKS,
    parent: ParentStoreCategoryValue.ENTERTAINMENT,
  },
  {
    label: 'Movies',
    labelLong: 'Movies',
    slug: 'movies',
    value: StoreCategoryValue.MOVIES,
    parent: ParentStoreCategoryValue.ENTERTAINMENT,
  },
  {
    label: 'Music',
    labelLong: 'Music',
    slug: 'music',
    value: StoreCategoryValue.MUSIC,
    parent: ParentStoreCategoryValue.ENTERTAINMENT,
  },
  {
    label: 'Comics',
    labelLong: 'Comics',
    slug: 'comics',
    value: StoreCategoryValue.COMICS,
    parent: ParentStoreCategoryValue.ENTERTAINMENT,
  },
  {
    label: 'Automotive',
    labelLong: 'Automotive',
    slug: 'automotive',
    value: StoreCategoryValue.AUTOMOTIVE,
    parent: ParentStoreCategoryValue.ENTERTAINMENT,
  },
]
