import { toast as toastCore, Toaster as ToasterCore } from 'react-hot-toast'
import { Alert, AlertDescription, AlertProps, AlertTitle } from '../Alert'

export const Toaster = () => {
  return (
    <ToasterCore
      toastOptions={{
        position: 'top-center',
      }}
    />
  )
}

export type ToastOptions = {
  title: string
  message: string
  /**
   * Duration in seconds
   *
   * @default 5
   */
  duration?: number

  /**
   * ios-only - Control haptic feedback when the toast renders
   *
   * @default 'none'
   */
  haptic?: 'none' | 'success' | 'warning' | 'error'
}

const CustomToast = (
  props: Omit<AlertProps, 'children'> & { message: string; title: string },
) => {
  return (
    <Alert
      enterStyle={{
        scale: 1.1,
        y: -5,
        opacity: 0,
      }}
      opacity={1}
      scale={1}
      y={0}
      animation={'quick'}
      minWidth={250}
      maxWidth={350}
      data-testid="toast"
      {...props}
    >
      <AlertTitle data-testid="toastTitle">{props.title}</AlertTitle>
      <AlertDescription data-testid="toastDescription">
        {props.message}
      </AlertDescription>
    </Alert>
  )
}

export const toast = {
  success: (props: ToastOptions) => {
    toastCore.custom(
      <CustomToast
        message={props.message}
        title={props.title}
        status={'success'}
      />,
      {
        duration: (props.duration ?? 5) * 1000,
      },
    )
  },
  error: (props: ToastOptions) => {
    toastCore.custom(
      <CustomToast
        message={props.message}
        title={props.title}
        status={'error'}
      />,
      {
        duration: (props.duration ?? 5) * 1000,
      },
    )
  },
  info: (props: ToastOptions) => {
    toastCore.custom(
      <CustomToast
        message={props.message}
        title={props.title}
        status={'info'}
      />,
      {
        duration: (props.duration ?? 5) * 1000,
      },
    )
  },
}
