import { GetProps, styled } from '@tamagui/core'
import { YStack } from 'tamagui'
import { createStateContext } from '../../factory/createStateContext'
import { Text } from './Text'

const ListItem = styled(Text, {
  name: 'ListItemHTML',
  tag: 'li',
  selectable: true,
  cursor: 'text',
})

type ListItemProps = GetProps<typeof ListItem>

const OrderedListFrame = styled(YStack, {
  name: 'OrderedList',
  tag: 'ol',
  space: '$1',
})

type OrderedListFrameProps = GetProps<typeof OrderedListFrame>

const [OrderedListProvider, useOrderedList] = createStateContext<{
  childKeys: string[]
}>({ childKeys: [] })

export type OrderedListProps = OrderedListFrameProps & {
  childKeys: string[]
}

export const OrderedList = ({
  children,
  childKeys,
  ...rest
}: OrderedListProps) => {
  return (
    <OrderedListProvider initialValue={{ childKeys }}>
      <OrderedListFrame {...rest}>{children}</OrderedListFrame>
    </OrderedListProvider>
  )
}

export type OrderedListItemProps = ListItemProps & {
  /**
   * Number to assign list item
   */
  listNumber?: string
  /**
   * Unique key used for finding the index number to show from childKeys on the orderedlist provider
   */
  listKey?: string
}

export const OrderedListItem = ({
  children,
  listKey,
  listNumber,
  ...rest
}: OrderedListItemProps) => {
  const [{ childKeys }] = useOrderedList()

  const getNumberToShow = () => {
    if (listNumber) return listNumber + '.'

    if (listKey) {
      return childKeys.findIndex((x) => x === listKey) + 1 + '.'
    }

    return '\u2022'
  }

  return (
    <ListItem {...rest}>
      {getNumberToShow()} {children}
    </ListItem>
  )
}

export const UnorderedList = styled(YStack, {
  name: 'UnorderedList',
  tag: 'ul',
  space: '$1',
})

export type UnorderedListProps = GetProps<typeof UnorderedList>

export const UnorderedListItem = ({ children, ...rest }: ListItemProps) => {
  return (
    <ListItem {...rest}>
      <Text>{`\u2022`}</Text> {children}
    </ListItem>
  )
}

export type UnorderedListItemProps = ListItemProps
