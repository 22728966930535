import { ApolloClient, from, HttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { ErrorLink, onError } from '@apollo/client/link/error'
import { logout } from './logout'
import { API_URL, RUN_MOCKED_BACKEND } from '../config/env'
import { SecureStore } from '../utils/secure-storage'
import { toast } from '@bounty/creators-design-system'
import { logger } from '../utils/logger'

const httpLink = RUN_MOCKED_BACKEND
  ? new HttpLink({
      uri: `${API_URL}/graphql-api`,
    })
  : new BatchHttpLink({
      uri: `${API_URL}/graphql-api`,
    })

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = await SecureStore.getItem('authToken')
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      // The localStorage hook stringifies a string so we need to parse it before using
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

export const handleErrors: ErrorLink.ErrorHandler = ({
  graphQLErrors,
  networkError,
}) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ name, message }) => {
      if (
        name === 'AuthenticationError' ||
        message === 'Context creation failed: invalid signature'
      ) {
        logger.log(
          'Got authentication error from the server, clearing cache and logging out.',
        )

        logout(client)
      }
      toast.error({
        title: `Bounty Error: ${name}`,
        message,
        duration: 15,
      })
      logger.error(name, message)
    })
  }
  if (networkError) {
    logger.log(`[Network error]: ${networkError}`)
  }
}
const errorLink = onError(handleErrors)

const additiveLink = from([authLink, errorLink, httpLink])

export const uniqWith = <T>(arr: T[], fn: (a: T, b: T) => boolean) =>
  arr.filter(
    (element, index) => arr.findIndex((step) => fn(element, step)) === index,
  )

export const client = new ApolloClient({
  link: additiveLink,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          tikTokProfile: {
            // shorthand
            merge: true,
          },
          bounties: {
            keyArgs: false,
            merge(existing = { items: [] }, incoming, options) {
              const { mergeObjects } = options

              return {
                ...mergeObjects(existing, incoming),
                items: uniqWith(
                  [...existing.items, ...incoming.items],
                  (a, b) => a.__ref === b.__ref,
                ),
              }
            },
          },
        },
      },
    },
  }),
})
