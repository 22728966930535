export const light = {
  whiteAlpha: {
    50: 'rgba(255, 255, 255, 0.04)',
    100: 'rgba(255, 255, 255, 0.06)',
    200: 'rgba(255, 255, 255, 0.08)',
    300: 'rgba(255, 255, 255, 0.16)',
    400: 'rgba(255, 255, 255, 0.24)',
    500: 'rgba(255, 255, 255, 0.36)',
    600: 'rgba(255, 255, 255, 0.48)',
    700: 'rgba(255, 255, 255, 0.64)',
    800: 'rgba(255, 255, 255, 0.8)',
    900: 'rgba(255, 255, 255, 0.92)',
  },
  blackAlpha: {
    50: 'rgba(0, 0, 0, 0.04)',
    100: 'rgba(0, 0, 0, 0.06)',
    200: 'rgba(0, 0, 0, 0.08)',
    300: 'rgba(0, 0, 0, 0.16)',
    400: 'rgba(0, 0, 0, 0.24)',
    500: 'rgba(0, 0, 0, 0.36)',
    600: 'rgba(0, 0, 0, 0.48)',
    700: 'rgba(0, 0, 0, 0.64)',
    800: 'rgba(0, 0, 0, 0.8)',
    900: 'rgba(0, 0, 0, 0.92)',
  },
  gray: {
    50: '#FBFCFC',
    100: '#F8F9FB',
    200: '#F2F3F6',
    300: '#EAECF0',
    400: '#CFD4DC',
    500: '#98A1B2',
    600: '#576275',
    700: '#344053',
    800: '#1D2838',
    900: '#0F1728',
  },
  red: {
    50: '#FFF0F0',
    100: '#FED7D7',
    200: '#FEB2B2',
    300: '#FC8181',
    400: '#F56565',
    500: '#E53E3E',
    600: '#C53030',
    700: '#9B2C2C',
    800: '#822727',
    900: '#63171B',
  },
  yellow: {
    50: '#FFF7EC',
    100: '#FFEFDA',
    200: '#FFE0B5',
    300: '#FFD190',
    400: '#FFC26B',
    500: '#FFB347',
    600: '#CC8F38',
    700: '#996B2A',
    800: '#65471C',
    900: '#33230E',
  },
  green: {
    50: '#EAFFE0',
    100: '#D4FBC1',
    200: '#B9F29D',
    300: '#A6EC84',
    400: '#8FDA6A',
    500: '#73BD4E',
    600: '#5FA93A',
    700: '#50942E',
    800: '#3F7723',
    900: '#32611B',
  },
  purple: {
    50: '#EDEDFE',
    100: '#DADBFD',
    200: '#BFC0FB',
    300: '#7F80F7',
    400: '#5A5CF5',
    500: '#484AF4',
    600: '#4143DC',
    700: '#3A3BC3',
    800: '#282986',
    900: '#161649',
  },
}
