import { Variable } from '@tamagui/core'
import { tokens } from '../foundations'

export type ColorScheme =
  | 'primary'
  | 'success'
  | 'warning'
  | 'error'
  | 'neutral'
  | 'whiteAlpha'
  | 'blackAlpha'

type TokenTint = '50' | `${1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9}00`
type TokenColorMode = 'light' | 'dark'

export type SemanticColorToken = `${ColorScheme}.${TokenTint}`

export const buildSemanticColorTokens = ({
  mode,
}: {
  mode: TokenColorMode
}): Record<SemanticColorToken, string> => {
  const tokenToColorMap = [
    ['primary', 'purple'],
    ['success', 'green'],
    ['warning', 'yellow'],
    ['error', 'red'],
    ['neutral', 'gray'],
    ['whiteAlpha', 'whiteAlpha'],
    ['blackAlpha', 'blackAlpha'],
  ] as const

  const availableColorTints = [
    '50',
    '100',
    '200',
    '300',
    '400',
    '500',
    '600',
    '700',
    '800',
    '900',
  ] as const

  // @ts-expect-error - Can't figure out how to build a strongly typed object. Open to ideas!
  return tokenToColorMap.reduce<
    Partial<Record<SemanticColorToken, Variable<string | Variable<string>>>>
  >((acc, [token, color]) => {
    availableColorTints.forEach((tint, index) => {
      acc[`${token}.${tint}`] =
        tokens.color[`${color}.${availableColorTints[index]}.${mode}`]
    })
    return acc
  }, {})
}
