import {
  identifyMixpanel,
  setUserPropertyOnceMixpanel,
} from '@bounty/creators-design-system'
import { useEffect, useRef } from 'react'
import { useAuth } from '../hooks'
import { useInvite } from '../hooks/useInvite/useInvite'
import { setUser } from '../libs/sentry/sentry'
import { logger } from '../utils/logger'
import { DEPLOY_ENV } from '../config/env'
import { growthBook } from '../libs/growthBook'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { v4 as uuidv4 } from 'uuid'

const buildClarityLink = (userId: string) => {
  if (DEPLOY_ENV === 'production') {
    return `https://clarity.microsoft.com/projects/view/fjocdl5akc/dashboard?CustomUserId=is%3B${userId}`
  } else {
    return `https://clarity.microsoft.com/projects/view/fjochcpv2m/dashboard?CustomUserId=is%3B${userId}`
  }
}

const buildMixpanelLink = (userId: string) => {
  if (DEPLOY_ENV === 'production') {
    return `https://mixpanel.com/project/2907413/view/3436165/app/profile#distinct_id=${userId}`
  } else {
    return `https://mixpanel.com/project/2907412/view/3436164/app/profile#distinct_id=${userId}`
  }
}

export const AuthedAnalytics = () => {
  const [{ isAuthed, isInitializing, user }] = useAuth()
  const [{ ref }] = useInvite()
  // Ensures these methods are called only once
  const inited = useRef(false)
  useEffect(() => {
    if (isInitializing) return
    if (isAuthed === true && user && inited.current === false) {
      inited.current = true
      logger.log('Setting unique id', user.userId)

      identifyMixpanel(user.userId)
      if (ref) {
        logger.log('Setting ref', ref)
        setUserPropertyOnceMixpanel('source', ref)
      }
      setUser({
        id: user.userId,
        clarityLink: buildClarityLink(user.userId),
        mixpanelLink: buildMixpanelLink(user.userId),
      })
      growthBook.setAttributes({
        ...growthBook.getAttributes(),
        id: user.userId,
      })
    }
    if (isAuthed === false || !user) {
      const getOrCreateSessionId = async () => {
        const sessionId = await AsyncStorage.getItem('growthbookSessionId')
        if (!sessionId) {
          const newSessionId = uuidv4()
          await AsyncStorage.setItem('growthbookSessionId', newSessionId)
          growthBook.setAttributes({
            ...growthBook.getAttributes(),
            id: newSessionId,
          })
          return newSessionId
        }
        growthBook.setAttributes({
          ...growthBook.getAttributes(),
          id: sessionId,
        })
        return sessionId
      }
      getOrCreateSessionId()
    }
  }, [isAuthed, isInitializing, user, ref])
  return null
}
