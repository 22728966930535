/* eslint-disable no-restricted-imports */
import {
  captureException as captureExceptionCore,
  captureMessage as captureMessageCore,
  setUser as setUserCore,
} from '@sentry/react'

export const captureException = captureExceptionCore
export const captureMessage = captureMessageCore
export const setUser = setUserCore
