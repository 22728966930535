import { STORE_CATEGORIES, STORE_CATEGORIES_PARENTS } from '@bounty/constants'

export const maybeGetCategory = (category: string) => {
  const isCategory = ({ value }: { value: string }) => value === category
  const maybeParent = STORE_CATEGORIES_PARENTS.find(isCategory)
  const maybeChild = STORE_CATEGORIES.find(isCategory)

  return maybeParent ?? maybeChild
}

/**
 * Given a category string value from prospect.categories,
 * find either a parent or child category display value or undefined
 */
export const maybeGetDisplayValue = (category: string) => {
  const value = maybeGetCategory(category)
  return value?.label
}
