import { styled, GetProps, YStack, Image } from 'tamagui'
import { Text } from './text'

const FigureFrame = styled(YStack, {
  name: 'Figure', // useful for debugging, and Component themes
  tag: 'figure',
  space: '$4',
})

type FigureFrameProps = GetProps<typeof FigureFrame>

const FigCaption = styled(Text, {
  name: 'Figure', // useful for debugging, and Component themes
  tag: 'figcaption',
  color: '$neutral.600',
  fontSize: '$sm',
})

// Unused
// type FigCaptionProps = GetProps<typeof FigCaption>

export type FigureProps = FigureFrameProps & {
  value: { asset: string; alt: string; caption: string }
}

export const Figure = ({ value, ...rest }: FigureProps) => {
  return (
    <FigureFrame {...rest}>
      <Image
        src={value.asset}
        alt={value.alt}
        resizeMode="contain"
        height={300}
        width="100%"
        mx="auto"
        mb="2"
      />
      <FigCaption>{value.caption}</FigCaption>
    </FigureFrame>
  )
}
