import { Variable } from '@tamagui/core'
import { light } from './light'
import { dark } from './dark'

/**
 * Adds light and dark to our colors so tamagui can work with them
 *
 * https://github.com/tamagui/tamagui/blob/799d720fbff0f498a0ddcf735567d34f747d5c1d/packages/theme-base/src/tokens.tsx#L125
 */

function postfixObjKeys<
  A extends { [key: string]: Variable<string> | string },
  B extends string,
>(
  obj: A,
  postfix: B,
): {
  [Key in `${keyof A extends string ? keyof A : never}.${B}`]:
    | Variable<string>
    | string
} {
  return Object.fromEntries(
    Object.entries(obj).map(([k, v]) => [`${k}.${postfix}`, v]),
  ) as any
}

type TokenType =
  | 'whiteAlpha'
  | 'blackAlpha'
  | 'gray'
  | 'red'
  | 'yellow'
  | 'green'
  | 'purple'
type TokenTint = '50' | `${1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9}00`
export type SemanticColorToken = `${TokenType}.${TokenTint}`

/**
 * Flattens our nested color object into something that tamagui can work with
 */
const makeColorObject = (
  colorMap: typeof light,
): Record<SemanticColorToken, string> => {
  return Object.entries(colorMap).reduce((accu, [color, colorObject]) => {
    Object.entries(colorObject).forEach(([colorTintKey, colorTintValue]) => {
      // @ts-expect-error - Index type error
      accu[`${color}.${colorTintKey}`] = colorTintValue
    })

    return accu
  }, {}) as any
}

const lightColors = makeColorObject(light)
const darkColors = makeColorObject(dark)

export const color = {
  ...postfixObjKeys(lightColors, 'light'),
  ...postfixObjKeys(darkColors, 'dark'),
}
