export type StoreTag = {
  label: string
  slug: string
  value: StoreTagValue
}

export enum StoreTagValue {
  GLUTEN_FREE = 'gluten_free',
  PLASTIC_FREE = 'plastic_free',
  CLEAN_BEAUTY = 'clean_beauty',
  VEGAN = 'vegan',
  SUSTAINABLE_AND_ETHICALLY_MADE = 'sustainable_and_ethically_made',
  MADE_IN_THE_USA = 'made_in_the_usa',
  MADE_IN_THE_UK = 'made_in_the_uk',
  VETERAN_OWNED = 'veteran_owned',
  ARTIST_MADE = 'artist_made',
  BIPOC_OWNED_BUSINESS = 'bipoc_owned_business',
  PARTNERS_WITH_A_PURPOSE = 'partners_with_a_purpose',
  MADE_IN_CAN = 'made_in_can',
  MADE_IN_AUS = 'made_in_aus',
  CERTIFIED_B_CORP = 'certified_b_corp',
  FEMALE_FOUNDED = 'female_founded',
  UNIQUE_FINDS = 'unique_finds',
  GREAT_GIFT = 'great_gift',
  SMALL_BUSINESS = 'small_business',
  LUXURY = 'luxury',
  WEDDING = 'wedding',
  HANDMADE = 'handmade',
  AS_SEEN_ON_SHARK_TANK = 'as_seen_on_shark_tank',
  IN_STORE = 'in_store',
  TIKTOK = 'TIKTOK_VIDEO',
  INSTAGRAM = 'INSTAGRAM_REEL',
}

export const STORE_TAGS: StoreTag[] = [
  {
    label: 'Gluten free',
    slug: 'gluten-free',
    value: StoreTagValue.GLUTEN_FREE,
  },
  {
    label: 'Plastic free',
    slug: 'plastic-free',
    value: StoreTagValue.PLASTIC_FREE,
  },
  {
    label: 'Clean beauty',
    slug: 'clean-beauty',
    value: StoreTagValue.CLEAN_BEAUTY,
  },
  { label: 'Vegan', slug: 'vegan', value: StoreTagValue.VEGAN },
  {
    label: 'Sustainable and Ethically Made',
    slug: 'sustainable-and-ethically-made',
    value: StoreTagValue.SUSTAINABLE_AND_ETHICALLY_MADE,
  },
  {
    label: 'Made in the USA',
    slug: 'made-in-the-usa',
    value: StoreTagValue.MADE_IN_THE_USA,
  },
  {
    label: 'Made in the UK',
    slug: 'made-in-the-uk',
    value: StoreTagValue.MADE_IN_THE_UK,
  },
  {
    label: 'Veteran Owned',
    slug: 'veteran-owned',
    value: StoreTagValue.VETERAN_OWNED,
  },
  {
    label: 'Artist Made',
    slug: 'artist-made',
    value: StoreTagValue.ARTIST_MADE,
  },
  {
    label: 'BIPOC Owned Business',
    slug: 'bipoc-owned-business',
    value: StoreTagValue.BIPOC_OWNED_BUSINESS,
  },
  {
    label: 'Partners with a Purpose',
    slug: 'partners-with-a-purpose',
    value: StoreTagValue.PARTNERS_WITH_A_PURPOSE,
  },
  {
    label: 'Made in CAN',
    slug: 'made-in-can',
    value: StoreTagValue.MADE_IN_CAN,
  },
  {
    label: 'Made in AUS',
    slug: 'made-in-aus',
    value: StoreTagValue.MADE_IN_AUS,
  },
  {
    label: 'Certified B-Corp',
    slug: 'certified-b-corp',
    value: StoreTagValue.CERTIFIED_B_CORP,
  },
  {
    label: 'Female Founded',
    slug: 'female-founded',
    value: StoreTagValue.FEMALE_FOUNDED,
  },
  {
    label: 'Unique finds',
    slug: 'unique-finds',
    value: StoreTagValue.UNIQUE_FINDS,
  },
  { label: 'Great gift', slug: 'great-gift', value: StoreTagValue.GREAT_GIFT },
  {
    label: 'Small business',
    slug: 'small-business',
    value: StoreTagValue.SMALL_BUSINESS,
  },
  { label: 'Luxury', slug: 'luxury', value: StoreTagValue.LUXURY },
  { label: 'Wedding', slug: 'wedding', value: StoreTagValue.WEDDING },
  { label: 'Handmade', slug: 'handmade', value: StoreTagValue.HANDMADE },
  {
    label: 'As seen on Shark Tank',
    slug: 'as-seen-on-shark-tank',
    value: StoreTagValue.AS_SEEN_ON_SHARK_TANK,
  },
  {
    label: 'In-store',
    slug: 'in-store',
    value: StoreTagValue.IN_STORE,
  },
  {
    label: 'TikTok',
    slug: 'tiktok',
    value: StoreTagValue.TIKTOK,
  },
  {
    label: 'Instagram',
    slug: 'instagram',
    value: StoreTagValue.INSTAGRAM,
  },
]
