export const BOUNTY_UTM_PARAMS =
  'utm_source=bounty&utm_medium=referral&utm_campaign=bounty'

interface PixelParams {
  userId?: string
  sessionId?: string
  dealId?: string
  productId?: string
  location?: 'public' | 'creators'
}

const PARAM_MAPPING: Record<keyof PixelParams, string> = {
  userId: 'bid',
  sessionId: 'bsid',
  dealId: 'bdid',
  productId: 'bpid',
  location: 'bloc',
}
export const makePixelParams = (values: PixelParams) => {
  // if (!values['sessionId']) values['sessionId'] = uuidv4()
  const converted = Object.fromEntries(
    Object.entries(values)
      .filter(([, v]) => !!v)
      .map(([k, v]) => [PARAM_MAPPING[k as keyof PixelParams], v]),
  )

  converted['ref'] = 'bounty'
  return new URLSearchParams(converted).toString()
}

export const appendBountyUtmParams = (url: string, additionalParams = '') => {
  return `${url}?${BOUNTY_UTM_PARAMS}${
    additionalParams ? `&${additionalParams}` : ''
  }`
}

export const MAX_BOUNTY_ACCEPT_ORDER_AFTER_DELIVERY_DAYS = 15

/**
 * This is a percentage between 0-1
 *
 * e.g. if it is 0.25 then when we get to 7500 out of 10,000, the limit will be hit
 */
export const WARNING_SOFT_LIMIT_BUFFER_PCT = 25
