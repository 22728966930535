import {
  ChatTeardropText,
  GearSix,
  House,
  ShoppingBag,
  Star,
  DotsThreeOutline,
  CaretLeft,
  CaretRight,
  CaretUp,
  CaretDown,
  X,
  TextAlignLeft,
  ArrowLeft,
  ArrowRight,
  Compass,
  Lightning,
  Money,
  Gift,
  Info,
  Check,
  Prohibit,
  ShoppingCart,
  DotsNine,
  HandPointing,
  ArrowsHorizontal,
  Warning,
  CheckCircle,
  StripeLogo,
  At,
  CurrencyCircleDollar,
  FrameCorners,
  Copyright,
  UsersThree,
  Play,
  Timer,
  Pause,
  Phone,
  Storefront,
  HashStraight,
  CurrencyDollar,
  ThumbsDown,
  Package,
} from 'tamagui-phosphor-icons'

export const HomeIcon = House
export const MessagesIcon = ChatTeardropText
export const SettingsIcon = GearSix
export const ShopIcon = ShoppingBag
export const BountiesIcon = Star
export const ThreeDotsIcon = DotsThreeOutline
export const HeaderBackIcon = CaretLeft
export const CaretLeftIcon = CaretLeft
export const CaretRightIcon = CaretRight
export const CaretUpIcon = CaretUp
export const CaretDownIcon = CaretDown
export const CloseIcon = X
export const HamburgerIcon = TextAlignLeft
export const ArrowLeftIcon = ArrowLeft
export const ArrowRightIcon = ArrowRight
export const CompassIcon = Compass
export const LightningIcon = Lightning
export const MoneyIcon = Money
export const GiftIcon = Gift
export const InfoIcon = Info
export const CheckIcon = Check
export const ProhibitIcon = Prohibit
export const CartIcon = ShoppingCart
export const DotsIcon = DotsNine
export const HandPointingIcon = HandPointing
export const ArrowsHorizontalIcon = ArrowsHorizontal
export const ErrorIcon = Warning
export const CheckCircleIcon = CheckCircle
export const StripeIcon = StripeLogo
export const AtIcon = At
export const DollarCircleIcon = CurrencyCircleDollar
export const DollarIcon = CurrencyDollar
export const FrameCornersIcon = FrameCorners
export const CopyrightIcon = Copyright
export const CommunityIcon = UsersThree
export const PlayIcon = Play
export const PauseIcon = Pause
export const StopWatchIcon = Timer
export const PhoneIcon = Phone
export const StorefrontIcon = Storefront
export const HashtagIcon = HashStraight
export const ThumbsDownIcon = ThumbsDown
export const StarIcon = Star
export const PackageIcon = Package
