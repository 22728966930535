import {
  AnimatePresence,
  CloseIcon,
  IconButton,
  isWeb,
  styled,
  YStack,
} from '@bounty/creators-design-system'
import { useVideoPlayerState } from '../provider/videoPlayer'
import { Video } from './Video'

const videoAspectRatio = 1.72
const mobileWidth = 180
const tabletWidth = 240
const desktopWidth = 300

const AnimatableVideoPlayer = styled(YStack, {
  // @ts-expect-error - It's there on web
  position: isWeb ? 'fixed' : 'absolute',
  // TODO: Fix this on the bottom when the banner is not shown
  bottom: 120,
  right: 20,
  zIndex: 10000,
  height: videoAspectRatio * mobileWidth,
  width: mobileWidth,
  $gtSm: {
    height: videoAspectRatio * tabletWidth,
    width: tabletWidth,
  },
  $gtMd: {
    height: videoAspectRatio * desktopWidth,
    width: desktopWidth,
  },
  variants: {
    exit: {
      true: {
        opacity: 0,
      },
    },
  },
}) as typeof YStack

export const VideoPlayer = () => {
  const [{ videoUrl, poster, videoConfig }, setVideoPlayerState] =
    useVideoPlayerState()

  return (
    <AnimatePresence exitVariant={'exit'}>
      {!!videoUrl && (
        <AnimatableVideoPlayer
          enterStyle={{
            scale: 1.1,
            y: -5,
            opacity: 0,
          }}
          opacity={1}
          key={videoUrl}
          scale={1}
          y={0}
          animation={'quick'}
        >
          <YStack overflow="hidden" borderRadius={'$5'}>
            <Video
              url={videoUrl}
              poster={poster ?? undefined}
              {...videoConfig}
            />
          </YStack>
          <IconButton
            icon={CloseIcon}
            position="absolute"
            event="Video Player Close Icon Clicked"
            colorScheme="neutral"
            variant="solid"
            top={-30}
            right={-0}
            opacity={0.5}
            size="$xs"
            zIndex={10}
            onPress={() =>
              setVideoPlayerState((x) => ({
                ...x,
                videoUrl: null,
                poster: null,
                videoConfig: {},
              }))
            }
          />
        </AnimatableVideoPlayer>
      )}
    </AnimatePresence>
  )
}
