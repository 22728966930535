import { createAnimations } from '@tamagui/animations-react-native'

export const animations = createAnimations({
  bouncy: {
    type: 'spring',
    damping: 10,
    mass: 0.9,
    stiffness: 100,
  },
  lazy: {
    type: 'spring',
    damping: 20,
    stiffness: 60,
  },
  molasses: {
    type: 'timing',
    duration: 1000 * 60 * 1.5,
  },
  quick: {
    type: 'spring',
    damping: 20,
    mass: 1.2,
    stiffness: 250,
  },
  nav: {
    type: 'spring',
    damping: 200,
    mass: 1.2,
    stiffness: 250,
  },
  // Hacky workaround because tamagui doesn't have
  // layout group animations
  productBrief1: {
    type: 'timing',
    delay: 150,
    duration: 600,
  },
  productBrief2: {
    type: 'timing',
    delay: 750,
    duration: 600,
  },
  productBrief3: {
    type: 'timing',
    delay: 1350,
    duration: 600,
  },
})
