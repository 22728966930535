import { createTokens } from 'tamagui'
import { color } from './color'
import { space } from './space'
import { size } from './size'
import { radius } from './radius'
import { zIndex } from './z-index'

export * from './color'
export * from './typography'
export * from './media'

export const tokens = createTokens({
  zIndex: zIndex,
  radius,
  space,
  size,
  color,
})
