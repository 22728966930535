import { dark } from './dark'
import { BaseTheme, light } from './light'
import { createTheme } from 'tamagui'
// import { createTheme } from 'tamagui'

// console.log('base', { themesCore, tokens })

const dark_Input = createTheme({
  borderColorFocus: dark['primary.600'],
  placeholderColor: dark['neutral.600'],
  borderColorPress: dark['primary.700'],
})

const light_Input = createTheme({
  borderColorFocus: light['primary.500'],
})

const dark_Button = createTheme({
  borderColorFocus: dark['primary.500'],
})

const light_Button = createTheme({
  borderColorFocus: 'red',
  placeholderColor: 'pink',
  borderColorPress: 'yellow',
})

const subthemes = {
  dark_Input,
  light_Input,
  dark_Button,
  light_Button,
}

// 1. to get ThemeNames/Theme, first create an object with all themes
const allThemes = {
  dark: dark,
  light: light,
  ...subthemes,
}

// 2. then get the name type
type ThemeName = keyof typeof allThemes

// 3. then, create a Themes type that explicitly maps ThemeName => BaseTheme
type Themes = {
  [key in ThemeName]: BaseTheme
}

// 4. finally, export it with the stricter type
// @ts-expect-error - This is from the docs and I think he lies on his types in core
export const themes: Themes = allThemes
