import { IconProps } from 'tamagui-phosphor-icons'
import { GetProps } from '@tamagui/core'
import { ComponentType } from 'react'
import { Button } from './Button'

const getSizeForButton = (size: string | undefined) => {
  if (!size) throw new Error('Need size for button')

  switch (size) {
    case '$xs':
      return 12
    case '$sm':
      return 14
    case '$md':
      return 16
    case '$lg':
      return 28
    default:
      throw new Error('Need size for button')
  }
}

export type IconButtonProps = GetProps<typeof Button> & {
  icon: ComponentType<IconProps>
  iconProps?: IconProps
}

export const IconButton = ({
  icon: Icon,
  iconProps,
  size = '$md',
  ...rest
}: IconButtonProps) => {
  return (
    <Button
      {...rest}
      borderRadius={100000}
      padding={0}
      height={getSizeForButton(size) + 12}
      width={getSizeForButton(size) + 12}
      render={
        <Icon color="white" size={getSizeForButton(size)} {...iconProps} />
      }
      {...rest}
    />
  )
}
