import { createFont, isWeb } from '@tamagui/core'

export const createBodyFont = () => {
  return createFont({
    family: isWeb
      ? 'Figtree, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif'
      : 'Figtree',
    size: {
      xs: 12,
      sm: 14,
      md: 16,
      // TODO: Nate
      // Hack to keep tamagui internally happy
      3: 16,
      true: 16,
      lg: 18,
      xl: 20,
      '2xl': 24,
      '3xl': 30,
      '4xl': 36,
      '5xl': 48,
      '6xl': 60,
    },
    lineHeight: {
      xs: 18,
      sm: 21,
      md: 24,
      true: 24,
      lg: 27,
      xl: 30,
      '2xl': 36,
      '3xl': 45,
      '4xl': 54,
      '5xl': 72,
      '6xl': 90,
    },
    weight: {
      regular: '400',
      semibold: '600',
      bold: '700',
    },
    letterSpacing: {
      4: 0,
    },
    face: {
      // pass in weights as keys
      400: { normal: 'Figtree' },
      600: { normal: 'FigtreeSemiBold' },
      700: { normal: 'FigtreeBold' },
    },
  })
}
