export const intersection = <X, Y>(
  x: X[],
  y: Y[],
  callback: (x: X, y: Y) => boolean,
) => {
  return x.reduce<X[]>((accu, item) => {
    if (y.filter((x) => callback(item, x)).length > 0) {
      return [...accu, item]
    }

    return accu
  }, [])
}
