import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { SecureStore } from '../utils/secure-storage'

export const logout = (client: ApolloClient<NormalizedCacheObject>) => {
  return client.clearStore().then(async () => {
    await SecureStore.removeItem('authToken')

    // eslint-disable-next-line no-restricted-globals
    window.location.assign('/')
  })
}
