import React, { ReactNode } from 'react'
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react'
import {
  Button,
  H1,
  Link,
  LinkButton,
  Text,
  YStack,
} from '@bounty/creators-design-system'
import { useTrackPage } from '@bounty/creators-common/hooks/useTrackPage'

const MetricsComponent = () => {
  useTrackPage('Error Boundary')
  return null
}

// just a test
export const ErrorBoundary = ({ children }: { children: ReactNode }) => (
  <SentryErrorBoundary
    fallback={({ error }) => {
      return (
        <YStack
          height="100vh"
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          overflow="hidden"
        >
          <MetricsComponent />
          <YStack maxWidth="650px" px="$8">
            <H1 size="$2xl">An error occurred :(</H1>
            <Text mb="$6">
              We&apos;ve been notified of the error and are working on a fix. If
              this is urgent please{' '}
              <Link
                event="Error Boundary Email Us Clicked"
                style={{ display: 'inline' }}
                href={`mailto:support@bounty.co?subject=Urgent | Bounty creators error`}
              >
                email us
              </Link>
              .
            </Text>
            <Text mb="$8">
              <Text fontWeight="bold">Error:</Text> {error.name} {error.message}
            </Text>
            <YStack alignItems="flex-start" space="$4">
              <Button
                event="Error Boundary Try Again Button Clicked"
                onClick={() => {
                  window.location.reload()
                }}
              >
                Try again
              </Button>
              <LinkButton
                href="/feedback?ref=errorPage&notificationMedium=INAPP"
                variant="link"
                event="Error Boundary Submit Feedback Clicked"
              >
                Submit Feedback
              </LinkButton>
            </YStack>
          </YStack>
        </YStack>
      )
    }}
  >
    {children}
  </SentryErrorBoundary>
)
