import { createFont, isWeb } from '@tamagui/core'

export const createHeadingFont = () => {
  return createFont({
    family: isWeb
      ? 'Mona Sans, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif'
      : 'Mona Sans',
    size: {
      xs: 12,
      sm: 14,
      md: 16,
      // TODO: Nate
      // Hack to keep tamagui internally happy
      3: 16,
      lg: 18,
      xl: 20,
      '2xl': 24,
      '3xl': 30,
      '4xl': 36,
      '5xl': 48,
      '6xl': 60,
    },
    lineHeight: {
      xs: 14,
      sm: 17,
      md: 19,
      lg: 22,
      xl: 24,
      '2xl': 32,
      '3xl': 40,
      '4xl': 43,
      '5xl': 48,
      '6xl': 60,
    },
    weight: {
      regular: '400',
      semibold: '600',
      bold: '700',
    },
    letterSpacing: {
      4: 0,
    },
  })
}
