export const DEFAULT_PRODUCT_HEADLINE =
  'Share your experience with the product. Talking to the camera or including a voiceover often performs better.'

export const DEFAULT_PRODUCT_FEATURE_1 =
  'Include a short, engaging hook at the beginning of your video to get more views.'

export const DEFAULT_PRODUCT_FEATURE_2 =
  'Film in a well-lit area and make sure people can clearly hear you. Be authentic and avoid using filters.'

export const DEFAULT_PRODUCT_FEATURE_3 = `Try to keep the product in frame for a majority of the video.`

export const DEFAULT_PRODUCT_FEATURES = [
  DEFAULT_PRODUCT_FEATURE_1,
  DEFAULT_PRODUCT_FEATURE_2,
  DEFAULT_PRODUCT_FEATURE_3,
]
