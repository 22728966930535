export const radius = {
  0: 0,
  1: 4,
  2: 6,
  3: 8,
  4: 10,
  5: 12,
  6: 18,
  7: 20,
  8: 24,
  9: 32,
  10: 36,
  11: 40,
  12: 48,
}
