import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
  ReactNode,
} from 'react'
import { logger } from '../../utils/logger'
import { API_URL } from '../../config/env'
import decode from 'jwt-decode'
import { logout as logoutUser } from '../../apollo/logout'
import axios from 'redaxios'
import {
  CreatorJwtPayload,
  LoginSmsResponse,
  SubmitSmsCodeResponse,
} from '@bounty/types'
import { SignupNewUserResponse, SignupNewUserBody } from '@bounty/common'
import { isNil, isFunction } from '@bounty/utils'
import { PhoneCountry } from '@bounty/constants'
import {
  CreateUserErrorComponent,
  SendVerificationCodeErrorComponent,
  SubmitPhoneVerificationCodeErrorComponent,
} from './AuthErrorHandlers'
import { SecureStore } from '../../utils/secure-storage'
import { client } from '../../apollo/client'
import { captureException } from '../../libs/sentry/sentry'

const isJwtExpired = <T extends { exp: number; data: any }>(jwt: T) => {
  const current_time = new Date().getTime() / 1000
  return current_time > jwt.exp
}

export type AuthState = UnauthedState | AuthedState
const AuthStateContext = createContext<AuthState | undefined>(undefined)

export type AuthDispatch = {
  setAuthState: React.Dispatch<React.SetStateAction<AuthState>>
  // handleLoginSuccess: (token: string) => void
  logout: () => Promise<void>
  login: (params: {
    phoneNumber: string
    phoneNumberCountry: PhoneCountry
    isSignUpMode: boolean
    shopUrl?: string | null
    orderId?: string | null
    email?: string | null
  }) => Promise<void>
  createUser: (params: {
    shopUrl?: string | null
    orderId?: string | null
    email?: string | null
    phoneNumber: string
    phoneNumberCountry: PhoneCountry
  }) => Promise<SignupNewUserResponse | void>
  setSignUpStep: (s: UnauthedState['signUpStep']) => void
  submitPhoneVerificationCode: (params: {
    verificationCode: string
    phoneNumber: string
    phoneNumberCountry: PhoneCountry
  }) => Promise<boolean>
}
export const AuthDispatchContext = createContext<AuthDispatch | undefined>(
  undefined,
)

type UnauthedState = {
  signUpStep: 'login' | 'phoneVerification' | 'success'
  isAuthed: false
  isLoading: boolean
  isInitializing: boolean
  authErrorComponent: ReactNode | null
  user: null
  token: undefined
}

type AuthedState = {
  signUpStep: 'success'
  isAuthed: true
  isLoading: boolean
  isInitializing: boolean
  authErrorComponent: null
  user: CreatorJwtPayload['data']
  token: string
}

export const initialAuthState: UnauthedState = {
  signUpStep: 'login',
  isAuthed: false,
  isLoading: false,
  isInitializing: true,
  authErrorComponent: null,
  user: null,
  token: undefined,
}

export type AuthProviderProps = {
  /** Mostly used for testing but it seeds the initial auth state for the context */
  initialAuthState?: UnauthedState | AuthedState
  onInitialized?: () => void
  children: ReactNode | ((props: AuthState) => ReactNode)
}

export const AuthProvider = ({
  children,
  initialAuthState: initialAuthStateProp = initialAuthState,
  onInitialized,
}: AuthProviderProps) => {
  const [authState, setAuthState] = useState<AuthState>(initialAuthStateProp)
  const { token } = authState

  const { isInitializing } = authState
  useEffect(() => {
    if (isInitializing === false) {
      onInitialized?.()
    }
  }, [isInitializing, onInitialized])

  const handleToken = useCallback(
    async (token: string) => {
      try {
        const decodedToken = decode<CreatorJwtPayload>(token)
        if (isJwtExpired(decodedToken)) {
          throw new Error('Token is expired, sending to login!')
        }

        setAuthState((s) => ({
          ...s,
          signUpStep: 'success',
          token,
          isAuthed: true,
          isLoading: false,
          isInitializing: false,
          user: decodedToken.data,
          authErrorComponent: null,
        }))

        await SecureStore.setItem('authToken', token)

        return true
      } catch (error: any) {
        setAuthState((x) => ({
          ...x,
          signUpStep: 'login',
          isLoading: false,
          isInitializing: false,
          isAuthed: false,
          user: null,
          token: undefined,
          authErrorComponent: null,
        }))
        await SecureStore.removeItem('authToken')
        return false
      }
    },
    [setAuthState],
  )

  // Effect ran on mount of the app to check auth state and login if necessary
  useEffect(() => {
    const checkAuth = async () => {
      const authToken = await SecureStore.getItem('authToken')
      if (!authToken) {
        setAuthState((x) => ({
          ...x,
          signUpStep: 'login',
          isLoading: false,
          isAuthed: false,
          isInitializing: false,
          token: undefined,
          user: null,
          authErrorComponent: null,
        }))
        return
      }

      handleToken(authToken)
    }

    checkAuth()
  }, [handleToken, token])

  const handleLoginSuccess = useCallback(
    (token: string) => {
      handleToken(token)
    },
    [handleToken],
  )

  const logout: AuthDispatch['logout'] = useCallback(async () => {
    await logoutUser(client)

    setAuthState(initialAuthState)
  }, [])

  const sendVerificationCode = useCallback(
    async ({
      phoneNumber,
      phoneNumberCountry,
    }: {
      phoneNumber: string
      phoneNumberCountry: PhoneCountry
    }) => {
      setAuthState((x) => ({
        ...x,
        isLoading: true,
        authErrorComponent: null,
      }))

      try {
        const resp = await axios.post<LoginSmsResponse>(
          `${API_URL}/auth/sms/login`,
          {
            phone: `${phoneNumberCountry.prefix}${phoneNumber}`,
          },
        )
        const { code } = resp.data

        // 200 type auth code response
        setAuthState((x) => {
          if (code === 'CODE_SENT') {
            return {
              ...x,
              signUpStep: 'phoneVerification',
              isAuthed: false,
              isLoading: false,
              user: null,
              token: undefined,
              authErrorComponent: null,
            }
          }

          return {
            ...x,
            isAuthed: false,
            isLoading: false,
            user: null,
            token: undefined,
            signUpStep: 'login',
            authErrorComponent: (
              <SendVerificationCodeErrorComponent response={resp.data} />
            ),
          }
        })
      } catch (e: any) {
        logger.error(e)
        setAuthState((x) => {
          return {
            ...x,
            isAuthed: false,
            isLoading: false,
            user: null,
            token: undefined,
            signUpStep: 'login',
            authErrorComponent: (
              <SendVerificationCodeErrorComponent response={e.data} />
            ),
          }
        })
      }
    },
    [setAuthState],
  )

  const createUser: AuthDispatch['createUser'] = useCallback(
    async ({ shopUrl, orderId, email, phoneNumberCountry, phoneNumber }) => {
      const body = {
        phone: `${phoneNumberCountry.prefix}${phoneNumber}`,
        ...(shopUrl ? { shopUrl } : {}),
        ...(orderId ? { orderId } : {}),
        ...(email ? { email } : {}),
      } as SignupNewUserBody
      logger.log('Sending create user with body:', body)
      const resp = await axios
        .post<SignupNewUserResponse>(`${API_URL}/signup/create-user`, body)
        .catch(async (e: any) => {
          if (!e?.data) {
            // no response data means it was an error thrown most likely
            captureException(e)
            logger.error(e)
          }

          if (e.data?.code === 'DUPLICATE_PHONE_NUMBER') {
            await sendVerificationCode({ phoneNumber, phoneNumberCountry })
            return
          }

          return setAuthState((x) => ({
            ...x,
            isAuthed: false,
            isLoading: false,
            user: null,
            token: undefined,
            signUpStep: 'login',
            authErrorComponent: (
              <CreateUserErrorComponent
                phoneNumber={phoneNumber}
                phoneNumberCountry={phoneNumberCountry}
                response={e.data}
              />
            ),
          }))
        })

      if (!resp) {
        return
      }

      return resp.data
    },
    [sendVerificationCode, setAuthState],
  )

  const login: AuthDispatch['login'] = useCallback(
    async ({
      isSignUpMode = false,
      shopUrl,
      orderId,
      email,
      phoneNumber,
      phoneNumberCountry,
    }) => {
      setAuthState((x) => ({
        ...x,
        isAuthed: false,
        isLoading: true,
        user: null,
        token: undefined,
        signUpStep: 'login',
        authErrorComponent: null,
      }))

      // Clear any old tokens just in case
      await SecureStore.removeItem('authToken')

      if (isSignUpMode) {
        const data = await createUser({
          shopUrl,
          orderId,
          email,
          phoneNumber,
          phoneNumberCountry,
        })
        if (isNil(data) || data?.success === false) {
          return
        }
      }

      sendVerificationCode({ phoneNumber, phoneNumberCountry })
    },
    [sendVerificationCode, createUser],
  )

  const setSignUpStep: AuthDispatch['setSignUpStep'] = useCallback(
    (s) => {
      setAuthState((x) => {
        if (x.isAuthed === true) {
          if (s !== 'success') {
            logger.warn(
              'Cannot set sign up step to anything but success when user is logged in! Ignoring.',
            )
          }
          return x
        }
        return { ...x, signUpStep: s }
      })
    },
    [setAuthState],
  )

  const submitPhoneVerificationCode: AuthDispatch['submitPhoneVerificationCode'] =
    useCallback(
      async ({ verificationCode, phoneNumberCountry, phoneNumber }) => {
        setAuthState((x) => ({
          ...x,
          isAuthed: false,
          isLoading: true,
          user: null,
          token: undefined,
          authErrorComponent: null,
        }))

        logger.log(
          'Submitting verification code for number:',
          `${phoneNumberCountry.prefix}${phoneNumber}`,
        )

        try {
          const resp = await axios.post<SubmitSmsCodeResponse>(
            `${API_URL}/auth/sms/submit-code`,
            {
              phone: `${phoneNumberCountry.prefix}${phoneNumber}`,
              code: verificationCode,
            },
          )
          const { code } = resp.data
          if (code === 'CODE_VALID') {
            const { token } = resp.data
            handleLoginSuccess(token)
            return true
          }

          // fail state 200
          setAuthState((x) => ({
            ...x,
            isAuthed: false,
            isLoading: false,
            user: null,
            token: undefined,
            signUpStep: 'phoneVerification',
            authErrorComponent: (
              <SubmitPhoneVerificationCodeErrorComponent
                sendVerificationCode={sendVerificationCode}
                response={resp.data}
                phoneNumberCountry={phoneNumberCountry}
                phoneNumber={phoneNumber}
              />
            ),
          }))
          return false
        } catch (e: any) {
          // fail state non-200
          logger.error(e)

          setAuthState((x) => ({
            ...x,
            isAuthed: false,
            isLoading: false,
            user: null,
            token: undefined,
            signUpStep: 'phoneVerification',
            authErrorComponent: (
              <SubmitPhoneVerificationCodeErrorComponent
                sendVerificationCode={sendVerificationCode}
                response={e.data}
                phoneNumberCountry={phoneNumberCountry}
                phoneNumber={phoneNumber}
              />
            ),
          }))
          return false
        }
      },
      [sendVerificationCode, handleLoginSuccess],
    )

  return (
    <AuthStateContext.Provider value={authState}>
      <AuthDispatchContext.Provider
        value={{
          setAuthState,
          logout,
          createUser,
          login,
          setSignUpStep,
          submitPhoneVerificationCode,
        }}
      >
        {isFunction(children) ? children(authState) : children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  )
}

export const useAuthState = (): AuthState => {
  const context = useContext(AuthStateContext)

  if (!context) {
    throw new Error('useAuthState must be used within a AuthProvider.')
  }

  return context
}

export const useAuthDispatch = (): AuthDispatch => {
  const context = useContext(AuthDispatchContext)

  if (!context) {
    throw new Error('useAuthDispatch must be used within a AuthProvider.')
  }

  return context
}

export const useAuth = (): [AuthState, AuthDispatch] => {
  return [useAuthState(), useAuthDispatch()]
}
