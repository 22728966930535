import { isString, isBoolean, isNil } from './helpers'

export const requiredString = (x: unknown, name: string) => {
  if (isNil(x)) {
    throw new Error(
      `ENVIRONMENTAL VARIABLE ERROR:The environmental variable, ${name}, was not given. Make sure this is included in the .env file for the environment you are developing on. Received: ${x}`,
    )
  }
  if (!isString(x)) {
    throw new Error(
      `ENVIRONMENTAL VARIABLE ERROR: Argument must be of type string. Received: ${x}, name: ${name}. Make sure this variable is the correct type.`,
    )
  }
  if (x.length === 0) {
    throw new Error(
      `ENVIRONMENTAL VARIABLE ERROR: Argument is an empty string for ${name}. This is most likely not what you want. If it is, remove this check to log a warning.`,
    )
  }

  return x
}

/**
 * Will throw unless PRISMA_DB_URL is provided in env
 */
export const getDBHostName = () => {
  return requiredString(process.env.PRISMA_DB_URL, 'PRISMA_DB_URL')
    .split('@')[1]
    .split(':')[0]
}

export const requiredBoolean = (x: unknown, name: string): boolean => {
  if (x === 'true') {
    return true
  }
  if (x === 'false') {
    return false
  }
  if (x == null) {
    return false
  }
  if (x === '') {
    return false
  }
  if (isBoolean(x)) {
    return x
  }

  throw new Error(
    `ENVIRONMENTAL VARIABLE ERROR: Argument must be castable to type boolean. Received: ${x}, name: ${name}.`,
  )
}

// development is local env, "dev" is the deployed dev env to AWS.
// test is always testing mode, never deployed to AWS.
export const prettyPrintEnvironments = (env: string): boolean =>
  env === 'test' || env === 'development'
