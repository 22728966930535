import { SignupNewUserResponseFail } from '@bounty/common'
import { PhoneCountry } from '@bounty/constants'
import { FormErrorMessage, Text } from '@bounty/creators-design-system'
import { LoginSmsResponse, SubmitSmsCodeResponse } from '@bounty/types'
import { UnreachableCaseError } from '@bounty/utils'

export const CreateUserErrorComponent = ({
  response,
}: {
  phoneNumber: string
  phoneNumberCountry: PhoneCountry
  response?: SignupNewUserResponseFail
}) => {
  if (!response?.code) {
    return (
      <FormErrorMessage data-testid="login-error">
        An error occurred. Please try again.
      </FormErrorMessage>
    )
  }

  switch (response.code) {
    case 'DUPLICATE_PHONE_NUMBER':
      return null
    case 'INVALID_PHONE_NUMBER':
      return (
        <FormErrorMessage data-testid="login-error">
          Phone number is invalid. Unfortunately, Bounty is not currently
          available to users outside of the United States or Canada.
        </FormErrorMessage>
      )
    case 'INVALID_BODY':
    case 'SERVER_ERROR':
    case 'INVALID_STORE_URL':
      return (
        <FormErrorMessage data-testid="login-error">
          An unknown error occurred. Please try again.
        </FormErrorMessage>
      )
    case 'SIGNUP_SUCCESS':
      return null
    case 'RATE_LIMIT':
    case 'RATE_LIMIT2':
      return (
        <FormErrorMessage data-testid="login-error">
          You've made too many attempts, please wait a few minutes before trying
          again.
        </FormErrorMessage>
      )
    case 'INVALID_MOBILE_NUMBER_TYPE':
      return (
        <FormErrorMessage data-testid="login-error">
          Looks like that phone number is not a valid mobile number.
        </FormErrorMessage>
      )
    default:
      throw new UnreachableCaseError(response.code)
  }
}

export const SendVerificationCodeErrorComponent = ({
  response,
}: {
  response?: LoginSmsResponse
}) => {
  if (!response?.code) {
    return (
      <FormErrorMessage data-testid="login-error">
        An error occurred. Please try again.
      </FormErrorMessage>
    )
  }

  switch (response.code) {
    // no-op
    case 'CODE_SENT':
      return null
    case 'INVALID_NUMBER':
      return (
        <FormErrorMessage data-testid="login-error">
          Your phone number was not valid. Please check your number and try
          again.
        </FormErrorMessage>
      )
    case 'SERVER_ERROR':
      return (
        <FormErrorMessage data-testid="login-error">
          An unknown error occurred. Please try again.
        </FormErrorMessage>
      )
    case 'UNKNOWN_NUMBER':
      return (
        <FormErrorMessage data-testid="login-error">
          Could not find a phone number for that account.
        </FormErrorMessage>
      )
    case 'RATE_LIMIT':
    case 'RATE_LIMIT2':
      return (
        <FormErrorMessage data-testid="login-error">
          You've made too many attempts, please wait a few minutes before trying
          again.
        </FormErrorMessage>
      )
    case 'USER_NOT_FOUND':
      return (
        <FormErrorMessage data-testid="login-error">
          User not found. Have you signed up for Bounty?
        </FormErrorMessage>
      )
    case 'ACCOUNT_DISABLED':
      return (
        <FormErrorMessage data-testid="login-error">
          This account is deleted.
        </FormErrorMessage>
      )
    case 'INVALID_MOBILE_NUMBER_TYPE':
      return (
        <FormErrorMessage data-testid="login-error">
          Looks like that phone number is not a valid mobile number.
        </FormErrorMessage>
      )
    default:
      throw new UnreachableCaseError(response.code)
  }
}

export const SubmitPhoneVerificationCodeErrorComponent = ({
  response,
  sendVerificationCode,
  phoneNumber,
  phoneNumberCountry,
}: {
  response?: SubmitSmsCodeResponse
  phoneNumber: string
  phoneNumberCountry: PhoneCountry
  sendVerificationCode: (params: {
    phoneNumber: string
    phoneNumberCountry: PhoneCountry
  }) => void
}) => {
  if (!response?.code) {
    return (
      <FormErrorMessage data-testid="login-error">
        An error occurred. Please try again.
      </FormErrorMessage>
    )
  }

  const { code } = response

  switch (code) {
    case 'CODE_INVALID':
      return (
        <FormErrorMessage data-testid="login-error">
          Verification code was incorrect. Please{' '}
          <Text
            color="$error.500"
            size="$xs"
            textDecorationLine="underline"
            onPress={() =>
              sendVerificationCode({ phoneNumber, phoneNumberCountry })
            }
          >
            try again
          </Text>
          .
        </FormErrorMessage>
      )
    case 'SERVER_ERROR':
      return (
        <FormErrorMessage data-testid="login-error">
          An unknown error occurred. Please{' '}
          <Text
            color="$error.500"
            size="$xs"
            textDecorationLine="underline"
            onPress={() =>
              sendVerificationCode({ phoneNumber, phoneNumberCountry })
            }
          >
            try again
          </Text>
          .
        </FormErrorMessage>
      )
    case 'UNKNOWN_NUMBER':
      return (
        <FormErrorMessage data-testid="login-error">
          Could not find a phone number for that account. Please try again .
        </FormErrorMessage>
      )
    case 'INVALID_NUMBER':
      return (
        <FormErrorMessage data-testid="login-error">
          Your phone number was not valid. Please check your number and try
          again.
        </FormErrorMessage>
      )
    case 'ACCOUNT_DISABLED':
      return (
        <FormErrorMessage data-testid="login-error">
          This account is deleted.
        </FormErrorMessage>
      )
    case 'CODE_VALID':
      return null
    case 'RATE_LIMIT':
    case 'RATE_LIMIT2':
      return (
        <FormErrorMessage data-testid="login-error">
          You've made too many attempts, please wait a few minutes before trying
          again.
        </FormErrorMessage>
      )
    default:
      throw new UnreachableCaseError(code)
  }
}
