/**
 * This is a hack to deal with Tamagui's hack of cheating on their types to make their
 * system go.
 *
 * https://github.com/tamagui/tamagui/issues/559
 */

import { space } from './space'

// We're stubbing space as size to be consistent with Chakra and to provide one universal spacing
// scale. This is also consistent with Tailwind and many other CSS libs
export const size = Object.fromEntries(
  Object.entries(space).map(([key, value]) => [`$${key}`, value]),
) as any as typeof space
