/**
 * Super simple paginate for mocked backend stuff
 */
export const paginate = <T>({
  skip: skipParam,
  take: takeParam,
  items,
}: {
  skip: number
  take: number
  items: T[]
}) => {
  const skip = skipParam ?? 0
  const take = takeParam ?? 5

  return {
    skip,
    take,
    count: items.length,
    items: items.slice(skip, skip + take),
  }
}
