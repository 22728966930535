import { createStateContext } from '../../factory'

export const [InviteProvider, useInvite] = createStateContext<{
  email: string | null
  phone: string | null
  orderId: string | null
  /** The id from redirects (like the thank you page) */
  anonymousBountyId: string | null
  shopUrl: string | null
  ref: string | null
}>({
  email: null,
  phone: null,
  orderId: null,
  anonymousBountyId: null,
  shopUrl: null,
  ref: null,
})
