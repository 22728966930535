import { isArray, isString } from '../utils'
import { useAnalytics } from './useAnalytics'

export type EventObject = { eventName: string; [x: string]: any }
export type EventType = string | string[] | EventObject | EventObject[] | null

export const useHandleEvent = () => {
  const { track } = useAnalytics()

  const produceTestIdFromEvent = (singleEvent: EventType) => {
    const produceString = (str: string) => str
    if (singleEvent == null) return

    if (isArray(singleEvent)) {
      const firstEvent = singleEvent[0]

      return isString(firstEvent)
        ? produceString(firstEvent)
        : produceString(firstEvent.eventName)
    } else {
      return isString(singleEvent)
        ? produceString(singleEvent)
        : produceString(singleEvent.eventName)
    }
  }

  const handleEvent = (event: EventType) => {
    const sendSingleEvent = (singleEvent: EventType) => {
      if (!singleEvent || isArray(singleEvent)) return

      if (isString(singleEvent)) {
        track(singleEvent)
      } else {
        const { eventName, ...rest } = singleEvent

        track(eventName, rest)
      }
    }

    if (event) {
      if (isArray(event)) {
        Promise.all(event.map((x) => sendSingleEvent(x)))
      } else {
        sendSingleEvent(event)
      }
    }
  }

  return { handleEvent, produceTestIdFromEvent }
}
