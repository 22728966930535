/* eslint-disable no-restricted-globals */
// This lib is buildable for types only to make Tamagui happy. We are not publishing
// so we don't need to respect this rule here.
// eslint-disable-next-line @nx/enforce-module-boundaries
import { requiredString, requiredBoolean } from '@bounty/utils'

export const API_URL: string = requiredString(
  process.env.NEXT_PUBLIC_API_URL,
  'NEXT_PUBLIC_API_URL',
)

export const DEPLOY_ENV: string = requiredString(
  process.env.NEXT_PUBLIC_DEPLOY_ENV,
  'NEXT_PUBLIC_DEPLOY_ENV',
)

export const SENTRY_DSN: string = requiredString(
  process.env.NEXT_PUBLIC_SENTRY_DSN,
  'NEXT_PUBLIC_SENTRY_DSN',
)

export const APP_NAME: string = requiredString(
  process.env.NEXT_PUBLIC_APP_NAME,
  'NEXT_PUBLIC_APP_NAME',
)

export const RUN_MOCKED_BACKEND: boolean = requiredBoolean(
  process.env.NEXT_PUBLIC_RUN_MOCKED_BACKEND,
  'NEXT_PUBLIC_RUN_MOCKED_BACKEND',
)

export const CLOUDINARY_URL: string = requiredString(
  process.env.NEXT_PUBLIC_CLOUDINARY_URL,
  'NEXT_PUBLIC_CLOUDINARY_URL',
)

export const COMMIT_REF: string = process.env.NEXT_PUBLIC_COMMIT_REF!

export const IS_BUILT_TARGETING_AN_ENVIRONMENT =
  process.env.NODE_ENV === 'production'

export const VIDEO_BUCKET_URL = requiredString(
  process.env.NEXT_PUBLIC_VIDEO_BUCKET_URL,
  'NEXT_PUBLIC_VIDEO_BUCKET_URL',
)

export const PUBLIC_ASSETS_BUCKET_URL = requiredString(
  process.env.NEXT_PUBLIC_ASSETS_BUCKET_URL,
  'NEXT_PUBLIC_ASSETS_BUCKET_URL',
)

export const SANITY_PROJECT_ID = requiredString(
  process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
  'NEXT_PUBLIC_SANITY_PROJECT_ID',
)

export const SANITY_DATASET = requiredString(
  process.env.NEXT_PUBLIC_SANITY_DATASET,
  'NEXT_PUBLIC_SANITY_DATASET',
)

export const CLARITY_PROJECT_ID = requiredString(
  process.env.NEXT_PUBLIC_CLARITY_PROJECT_ID,
  'NEXT_PUBLIC_CLARITY_PROJECT_ID',
)

export const GTAG_MEASUREMENT_ID = requiredString(
  process.env.NEXT_PUBLIC_GTAG_MEASUREMENT_ID,
  'NEXT_PUBLIC_GTAG_MEASUREMENT_ID',
)

export const GROWTH_BOOK_CLIENT_KEY = requiredString(
  process.env.NEXT_PUBLIC_GROWTH_BOOK_CLIENT_KEY,
  'NEXT_PUBLIC_GROWTH_BOOK_CLIENT_KEY',
)

export const MIXPANEL_PROJECT_TOKEN = requiredString(
  process.env.NEXT_PUBLIC_MIXPANEL_PROJECT_TOKEN,
  'NEXT_PUBLIC_MIXPANEL_PROJECT_TOKEN',
)

export const SAYMORE_WIDGET_URL: string = requiredString(
  process.env.NEXT_PUBLIC_SAYMORE_WIDGET_URL,
  'NEXT_PUBLIC_SAYMORE_WIDGET_URL',
)

export const SAYMORE_SURVEY_ID: string = requiredString(
  process.env.NEXT_PUBLIC_SAYMORE_SURVEY_ID,
  'NEXT_PUBLIC_SAYMORE_SURVEY_ID',
)
