import { Fragment, ReactNode, useState } from 'react'
import { Sheet as SheetCore, GetProps, XStack, XStackProps } from 'tamagui'
import { X } from 'tamagui-phosphor-icons'
import { IconButton, IconButtonProps } from './IconButton'
import { H3, H3Props } from './text'

export type SheetCloseButtonProps = Omit<IconButtonProps, 'debug' | 'icon'>

export const SheetCloseButton = (props: SheetCloseButtonProps) => {
  return (
    <IconButton
      size="$xs"
      backgroundColor={'$neutral.200'}
      iconProps={{ color: '$neutral.500' }}
      icon={X}
      pressStyle={{ backgroundColor: '$neutral.300' }}
      hoverStyle={{ backgroundColor: '$neutral.300' }}
      focusStyle={{ backgroundColor: '$neutral.300' }}
      {...props}
    />
  )
}

export type SheetTitleProps = H3Props & {
  children: ReactNode
}

export const SheetTitle = ({ children, ...props }: SheetTitleProps) => {
  return (
    <H3 size="$xl" {...props}>
      {children}
    </H3>
  )
}

export type SheetTitleContainerProps = XStackProps & {
  children: ReactNode
}

export const SheetTitleContainer = ({
  children,
  ...props
}: SheetTitleContainerProps) => {
  return (
    <XStack
      mb="$3"
      alignItems="center"
      justifyContent="space-between"
      {...props}
    >
      {children}
    </XStack>
  )
}

export type SheetProps = GetProps<typeof SheetCore> & {
  title?: ReactNode
  scroll?: boolean
  showCloseButton?: boolean
}

export const Sheet = ({
  open,
  children,
  onOpenChange,
  title,
  scroll = false,
  showCloseButton = true,
  ...props
}: SheetProps) => {
  const [position, setPosition] = useState(0)
  const Container = scroll ? SheetCore.ScrollView : Fragment

  return (
    <SheetCore
      forceRemoveScrollEnabled={open}
      modal={true}
      open={open}
      onOpenChange={onOpenChange}
      snapPoints={[85, 50, 25]}
      dismissOnSnapToBottom
      position={position}
      onPositionChange={setPosition}
      zIndex={100000}
      {...props}
    >
      <SheetCore.Overlay backgroundColor="$blackAlpha.800" />
      <SheetCore.Frame
        borderTopLeftRadius={'$7'}
        borderTopRightRadius={'$7'}
        f={1}
        px="$4"
        pt="$2"
        pb="$6"
        borderTopWidth={1}
        borderColor="$neutral.200"
      >
        <SheetCore.Handle
          alignSelf="center"
          height={5}
          width={36}
          borderRadius="$12"
          zIndex={100000}
          opacity={1}
          backgroundColor={'$neutral.500'}
        />
        {title ? (
          <SheetTitleContainer>
            <SheetTitle>{title}</SheetTitle>
            {showCloseButton && (
              <SheetCloseButton
                event={'Sheet Icon Button Close Clicked'}
                onPress={() => onOpenChange?.(false)}
              />
            )}
          </SheetTitleContainer>
        ) : (
          showCloseButton && (
            <SheetCloseButton
              event={'Sheet Icon Button Close Clicked'}
              onPress={() => onOpenChange?.(false)}
              position="absolute"
              top={20}
              zIndex={10}
              right={20}
            />
          )
        )}
        <Container>
          {/* <YStack borderRadius={'$3'} flex={1} backgroundColor={'$neutral.50'}> */}
          {children}
          {/* </YStack> */}
        </Container>
      </SheetCore.Frame>
    </SheetCore>
  )
}
