import { forwardRef } from 'react'
import {
  GetProps,
  Text as TextCore,
  TextProps as TextCoreProps,
  styled,
} from 'tamagui'
import { getVariableValue } from '@tamagui/core'
import type {
  FontSizeTokens,
  TextProps as TextPropsCore,
  VariantSpreadFunction,
} from '@tamagui/core'

export const getFontSized: VariantSpreadFunction<
  TextPropsCore,
  FontSizeTokens
> = (val = '$md', { fonts, theme, props }) => {
  const family = getVariableValue(props.fontFamily) || '$body'

  // TODO: Nate these types are wrong
  // @ts-expect-error - These types are wrong unfortunately
  const font = fonts[family] || fonts['$body']

  if (!font) {
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.warn('⚠️ no font found', {
        family,
        fontTokens: Object.keys(fonts),
        val,
      })
    }
    return {} as any
  }

  const fontFamily = font.family
  const fontSize = props.fontSize || font.size[val]
  const lineHeight = props.lineHeight || font.lineHeight[val]
  const fontWeight = props.fontWeight || '$regular'
  const letterSpacing = props.letterSpacing || font.letterSpacing[val]
  const fontStyle = props.fontStyle || font.style?.[val]
  const textTransform = props.textTransform || font.transform?.[val]
  const color = props.color || font.color?.[val] || theme.color
  const style = {
    color,
    fontStyle,
    textTransform,
    fontFamily,
    fontWeight,
    letterSpacing,
    fontSize,
    lineHeight,
  }

  if (process.env.NODE_ENV === 'development') {
    if (props['debug']) {
      // eslint-disable-next-line no-console
      console.groupCollapsed(
        '  🔹 getFont',
        val,
        props['fontWeight'],
        props['fow'],
      )
      // eslint-disable-next-line no-console
      console.log({ style, props, font })
      // eslint-disable-next-line no-console
      console.groupEnd()
    }
  }
  return style
}

/**
 * Extra wrapper around text in case we need to bail ourselves out with sizing props
 */
export const StyledText = styled(TextCore, {
  name: 'Text',
  fontFamily: '$body',
  variants: {
    size: getFontSized,
  } as const,

  defaultVariants: {
    size: '$md',
  },
})

export type TextProps = GetProps<typeof StyledText> & TextCoreProps

export const Text = forwardRef<any, TextProps>((props, ref) => {
  return <StyledText ref={ref} {...props} />
})
