import { useAnalytics } from '@bounty/creators-design-system'
import { useEffect } from 'react'

/**
 * Tracks a page render for analytics purposes. In most cases, we drive all analytic flows based off of
 * events since they are flows to progress through. However, in some cases we need a load event to understand
 * conversion rate. For example, we would like to see how many people activate on the invite page. Without knowing
 * the page was loaded we can't do the conversion rate.
 *
 * @note Only call at the root of a page!!
 */
export const useTrackPage = (
  pageName: string,
  properties?: Record<string, any>,
) => {
  const { track } = useAnalytics()

  useEffect(() => {
    track(`${pageName} Page Viewed`, properties)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [track])
}
