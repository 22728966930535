import { createTheme } from 'tamagui'
import { tokens } from '../foundations'

import { buildSemanticColorTokens } from './utils'

export const light = createTheme({
  background: '#fff',
  backgroundHover: tokens.color['gray.200.light'],
  backgroundPress: tokens.color['gray.300.light'],
  backgroundFocus: tokens.color['gray.400.light'],
  borderColor: tokens.color['gray.300.light'],
  borderColorHover: tokens.color['gray.500.light'],
  borderColorFocus: tokens.color['gray.600.light'],
  borderColorPress: tokens.color['gray.600.light'],
  placeholderColor: tokens.color['gray.500.light'],
  color: tokens.color['gray.800.light'],
  colorHover: tokens.color['gray.600.light'],
  colorPress: tokens.color['gray.900.light'],
  colorFocus: tokens.color['gray.800.light'],
  shadowColor: tokens.color['whiteAlpha.500.light'],
  shadowColorHover: tokens.color['whiteAlpha.600.light'],
  backgroundStrong: 'white',
  ...buildSemanticColorTokens({ mode: 'light' }),
})

export type BaseTheme = typeof light
