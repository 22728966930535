import {
  GetProps,
  Dialog as DialogCore,
  Adapt,
  useMedia,
  XStack,
} from 'tamagui'
import { X } from 'tamagui-phosphor-icons'
import { IconButton, IconButtonProps } from './IconButton'
import { Sheet, SheetProps } from './Sheet'

export type DialogCloseButtonProps = Omit<IconButtonProps, 'debug' | 'icon'>

export type DialogProps = GetProps<typeof DialogCore> & {
  onOpenChange: (newState: boolean) => void
  dialogContentProps?: GetProps<typeof DialogCore['Content']>
  sheetProps?: SheetProps
  customCloseComponent?: JSX.Element
}

export const Dialog = ({
  children,
  onOpenChange,
  dialogContentProps,
  sheetProps,
  customCloseComponent: CloseIconComponent,
  ...props
}: DialogProps) => {
  const { gtXs } = useMedia()
  return (
    <DialogCore modal {...props}>
      <Adapt when="sm" platform="touch">
        <Sheet
          zIndex={200000}
          modal
          dismissOnSnapToBottom
          onOpenChange={onOpenChange}
          scroll
          {...sheetProps}
        >
          <Adapt.Contents />
        </Sheet>
      </Adapt>

      <DialogCore.Portal>
        <DialogCore.Overlay
          key="overlay"
          animation="quick"
          backgroundColor="$blackAlpha.600"
          enterStyle={{ o: 0 }}
          exitStyle={{ o: 0 }}
        />

        <DialogCore.Content
          bordered
          elevate
          maxWidth={400}
          borderRadius="$5"
          key="content"
          animation={[
            'quick',
            {
              opacity: {
                overshootClamping: true,
              },
            },
          ]}
          enterStyle={{ x: 0, y: -20, opacity: 0, scale: 0.9 }}
          exitStyle={{ x: 0, y: 10, opacity: 0, scale: 0.95 }}
          {...dialogContentProps}
        >
          {gtXs && !CloseIconComponent && (
            <IconButton
              size="$xs"
              backgroundColor={'$neutral.200'}
              iconProps={{ color: '$neutral.500' }}
              icon={X}
              onPress={() => onOpenChange?.(false)}
              position="absolute"
              pressStyle={{ backgroundColor: '$neutral.300' }}
              hoverStyle={{ backgroundColor: '$neutral.300' }}
              focusStyle={{ backgroundColor: '$neutral.300' }}
              top={10}
              event={'Dialog Icon Button Close Clicked'}
              zIndex={10}
              right={10}
            />
          )}
          {CloseIconComponent && (
            <XStack
              position="absolute"
              zIndex={10}
              right={15}
              top={20}
              onPress={() => onOpenChange?.(false)}
            >
              {CloseIconComponent}
            </XStack>
          )}
          {children}
        </DialogCore.Content>
      </DialogCore.Portal>
    </DialogCore>
  )
}
