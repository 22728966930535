import React from 'react'
import { YStack } from '@bounty/creators-design-system'

// Zoho Sales IQ widget code
export default function ZohoSalesIQ({
  widgetId,
  userId,
}: {
  widgetId: string
  userId: string
}) {
  // @ts-expect-error blah
  // eslint-disable-next-line no-restricted-globals
  window.$zoho = window.$zoho || {}
  // @ts-expect-error blah
  // eslint-disable-next-line no-restricted-globals
  window.$zoho.salesiq = window.$zoho.salesiq || {
    widgetcode: widgetId,
    values: {
      userId: userId,
    },
    // eslint-disable-next-line
    ready: function () {},
  }
  const d = document
  const s = d.createElement('script')
  s.type = 'text/javascript'
  s.id = 'zsiqscript'
  s.defer = true
  s.src = 'https://salesiq.zoho.com/widget'
  const t = d.getElementsByTagName('script')[0]
  // @ts-expect-error blah
  t.parentNode.insertBefore(s, t)

  return <YStack id="zsiqwidget"></YStack>
}
