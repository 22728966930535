// Temporary shim to put the userId in localstorage for the shop page
// this allows us to bypass the phone number input for the shop

import { useEffect } from 'react'

// page redirects
export const useCreatorsRedirectShim = () => {
  // Use effect so it only runs client side
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)

    const ref = urlParams.get('ref')
    const userId = urlParams.get('userId')
    if (ref && userId && ['creators', 'sms', 'email'].includes(ref)) {
      localStorage.setItem('analyticsUserId', userId)
      localStorage.setItem('analyticsRef', ref)
    }
  }, [])
}
