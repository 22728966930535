// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck typescript upgraded to v5, but can't upgrade the tamagui to latest version yet
import { GetProps, styled } from '@tamagui/core'

import { Text } from './Text'

export const Paragraph = styled(Text, {
  name: 'Paragraph',
  tag: 'p',
  selectable: true,
  cursor: 'text',
})

export type ParagraphProps = GetProps<typeof Paragraph>

export const Em = styled(Text, {
  name: 'Em',
  tag: 'em',
  selectable: true,
  cursor: 'text',
  fontStyle: 'italic',
})

export type EmProps = GetProps<typeof Em>

export const Strong = styled(Text, {
  name: 'Strong',
  tag: 'strong',
  selectable: true,
  cursor: 'text',
  fontWeight: '$bold',
})

export type StrongProps = GetProps<typeof Strong>

export const Code = styled(Text, {
  name: 'Code',
  tag: 'core',
  selectable: true,
  cursor: 'text',
})

export type CodeProps = GetProps<typeof Code>

export const Underline = styled(Text, {
  name: 'Underline',
  tag: 'span',
  selectable: true,
  cursor: 'text',
  textDecorationLine: 'underline',
})

export type UnderlineProps = GetProps<typeof Underline>

export const StrikeThrough = styled(Text, {
  name: 'StrikeThough',
  tag: 'del',
  selectable: true,
  cursor: 'text',
  textDecorationLine: 'line-through',
})

export type StrikeThroughProps = GetProps<typeof StrikeThrough>
