import { createContext, useContext, useEffect, useState } from 'react'

export const createStateContext = <T,>(defaultInitialValue: T) => {
  const StateContext = createContext<T | undefined>(undefined)
  const DispatchContext = createContext<
    React.Dispatch<React.SetStateAction<T>> | undefined
  >(undefined)

  const StateProvider = ({
    children,
    initialValue,
    shouldUpdateOnInitialValueChange = false,
  }: {
    children?: React.ReactNode
    initialValue?: T
    /**
     * When the initial value changes (shallow), update the context with the value.
     *
     * Be careful! This can cause infinite loops. Memoizing the initial value is a good idea
     * here.
     *
     * @default false
     */
    shouldUpdateOnInitialValueChange?: boolean
  }) => {
    const [value, setter] = useState<T>(
      initialValue !== undefined ? initialValue : defaultInitialValue,
    )

    useEffect(() => {
      if (shouldUpdateOnInitialValueChange && initialValue) {
        setter(initialValue)
      }
    }, [initialValue, setter, shouldUpdateOnInitialValueChange])

    return (
      <StateContext.Provider value={value}>
        <DispatchContext.Provider value={setter}>
          {children}
        </DispatchContext.Provider>
      </StateContext.Provider>
    )
  }

  const useFactoryStateContext = () => {
    const state = useContext(StateContext)
    if (state == null) {
      throw new Error(
        `useStateContext must be used inside a StateProvider. Please wrap StateProvider above where you are invoking this hook.`,
      )
    }
    return state
  }

  const useFactoryDispatchContext = () => {
    const state = useContext(DispatchContext)
    if (state == null) {
      throw new Error(
        `useDispatchContext must be used inside a DispatchContext. Please wrap StateProvider above where you are invoking this hook.`,
      )
    }
    return state
  }

  const useFactoryContext = (): [
    ReturnType<typeof useFactoryStateContext>,
    ReturnType<typeof useFactoryDispatchContext>,
  ] => {
    return [useFactoryStateContext(), useFactoryDispatchContext()]
  }

  return [
    StateProvider,
    useFactoryContext,
    useFactoryStateContext,
    useFactoryDispatchContext,
  ] as const
}
