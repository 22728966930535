export const dark = {
  whiteAlpha: {
    50: 'rgba(255, 255, 255, 0.92)',
    100: 'rgba(255, 255, 255, 0.8)',
    200: 'rgba(255, 255, 255, 0.64)',
    300: 'rgba(255, 255, 255, 0.48)',
    400: 'rgba(255, 255, 255, 0.36)',
    500: 'rgba(255, 255, 255, 0.24)',
    600: 'rgba(255, 255, 255, 0.16)',
    700: 'rgba(255, 255, 255, 0.08)',
    800: 'rgba(255, 255, 255, 0.06)',
    900: 'rgba(255, 255, 255, 0.04)',
  },
  blackAlpha: {
    50: 'rgba(0, 0, 0, 0.92)',
    100: 'rgba(0, 0, 0, 0.8)',
    200: 'rgba(0, 0, 0, 0.64)',
    300: 'rgba(0, 0, 0, 0.48)',
    400: 'rgba(0, 0, 0, 0.36)',
    500: 'rgba(0, 0, 0, 0.24)',
    600: 'rgba(0, 0, 0, 0.16)',
    700: 'rgba(0, 0, 0, 0.08)',
    800: 'rgba(0, 0, 0, 0.06)',
    900: 'rgba(0, 0, 0, 0.04)',
  },
  gray: {
    50: '#161618',
    100: '#1C1C1F',
    200: '#232326',
    300: '#28282C',
    400: '#34343A',
    500: '#504F57',
    600: '#706F78',
    700: '#7E7D86',
    800: '#A09FA6',
    900: '#EDEDEF',
  },
  red: {
    50: '#1F1315',
    100: '#291415',
    200: '#3C181A',
    300: '#671E22',
    400: '#822025',
    500: '#AA2429',
    600: '#E5484D',
    700: '#F2555A',
    800: '#FF6369',
    900: '#FEECEE',
  },
  yellow: {
    50: '#1F1300',
    100: '#271700',
    200: '#341C00',
    300: '#4A2900',
    400: '#693F05',
    500: '#824E00',
    600: '#F1A10D',
    700: '#FEF3DD',
    800: '#FFB224',
    900: '#FFCB47',
  },
  green: {
    50: '#141807',
    100: '#181D08',
    200: '#1E260D',
    300: '#344213',
    400: '#415215',
    500: '#536716',
    600: '#87BE22',
    700: '#99D52A',
    800: '#C4F042',
    900: '#EFFBDD',
  },
  purple: {
    50: '#0E0E30',
    100: '#1C1D61',
    200: '#2B2C92',
    300: '#393BC3',
    400: '#484AF4',
    500: '#6C6EF6',
    600: '#9192F8',
    700: '#B5B6FA',
    800: '#DADAFC',
    900: '#F1F1FE',
  },
}
