import { PortableTextComponents } from '@portabletext/react'

// import {
//   TikTokEmbed as TikTokEmbedRaw,
//   YouTubeEmbed as YouTubeEmbedRaw,
// } from 'react-social-media-embed'
// import Refractor from 'react-refractor'
// import js from 'refractor/lang/javascript'
// import ts from 'refractor/lang/typescript'
// import css from 'refractor/lang/css'
// import markup from 'refractor/lang/markup'
import {
  Blockquote,
  Code,
  Em,
  Figure,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  OrderedList,
  OrderedListItem,
  Paragraph,
  StrikeThrough,
  Strong,
  Text,
  Underline,
  UnorderedList,
  UnorderedListItem,
} from '@bounty/creators-design-system'
import { Linking } from 'react-native'
import { logger } from '../utils/logger'

// Refractor.registerLanguage(js)
// Refractor.registerLanguage(ts)
// Refractor.registerLanguage(css)
// Refractor.registerLanguage(markup)

// const YoutubeEmbed = ({ value }: BoxProps & { value: any }) => {
//   return (
//     <Box mb="$4" display="flex" justifyContent="center">
//       <YouTubeEmbedRaw url={value.url} />
//     </Box>
//   )
// }

// const TikTokEmbed = ({ value }: BoxProps & { value: any }) => {
//   return (
//     <Box mb="$4">
//       <TikTokEmbedRaw url={value.url} />
//     </Box>
//   )
// }

// const getNodeForType = (item: any) => {
//   switch (item._type) {
//     case 'figure':
//       return <Figure value={item} mb="0" />
//     case 'tikTokEmbed':
//       logger.log('tikTokEmbed not enabled!')
//       return null
//     // return <TikTokEmbed value={item} mb="0" />
//     case 'youtubeEmbed':
//       logger.log('youtubeEmbed not enabled!')
//       return null
//     // return <YoutubeEmbed value={item} mb="0" />

//     default:
//       return null
//   }
// }

export const buildPortableTextComponents = (): PortableTextComponents => {
  return {
    block: {
      normal: ({ children }) => <Paragraph mb="$4">{children}</Paragraph>,
      blockquote: ({ children }) => <Blockquote>{children}</Blockquote>,
      h1: ({ children }) => <H1 mb="$6">{children}</H1>,
      h2: ({ children }) => <H2 mb="$5">{children}</H2>,
      h3: ({ children }) => <H3 mb="$4">{children}</H3>,
      h4: ({ children }) => <H4 mb="$4">{children}</H4>,
      h5: ({ children }) => <H5 mb="$4">{children}</H5>,
      h6: ({ children }) => <H6 mb="$4">{children}</H6>,
    },
    types: {
      grid: ({ value }) => {
        logger.error(
          'Grid is not implemented because it is hard to make universal',
        )
        return null
        // return (
        //   <ChakraGrid
        //     templateColumns={[
        //       'repeat(1, minmax(0, 1fr))',
        //       'repeat(1, minmax(0, 1fr))',
        //       `repeat(${value.columns}, minmax(0, 1fr))`,
        //     ]}
        //     gap="$4"
        //     mb="$4"
        //   >
        //     {value.items.map((item: any, index: number) => {
        //       return <Box key={index}>{getNodeForType(item)}</Box>
        //     })}
        //   </ChakraGrid>
        // )
      },
      tikTokEmbed: ({ value }) => {
        logger.error(
          'TikTok is not implemented because it is hard to make universal',
        )
        return null
      },
      youtubeEmbed: ({ value }) => {
        logger.error(
          'Youtube is not implemented because it is hard to make universal',
        )
        return null
        // return <YoutubeEmbed value={value} />
      },
      /**
       * NOTE: Make sure to import the stylesheet for the page if you use this. See blog.$blog.tsx for details.
       */
      code: ({ value }) => {
        logger.log(value)

        logger.error(
          'Code is not implemented because it is hard to make universal',
        )
        return null
        // return (
        //   <Refractor
        //     // In this example, `props` is the value of a `code` field
        //     language={value.language}
        //     value={value.code}
        //     markers={value.highlightedLines}
        //   />
        // )
      },
      figure: ({ value }) => {
        return <Figure value={value} />
      },
    },
    list: {
      number: ({ children, value }) => (
        <OrderedList my="$2" childKeys={value.children.map((x) => x._key!)}>
          {children}
        </OrderedList>
      ),
      bullet: ({ children }) => (
        <UnorderedList my="$2">{children}</UnorderedList>
      ),
    },
    listItem: ({ children, value }: any) =>
      value.listItem === 'number' ? (
        <OrderedListItem listKey={value._key}>{children}</OrderedListItem>
      ) : (
        <UnorderedListItem>{children}</UnorderedListItem>
      ),
    marks: {
      // Ex. 1: custom renderer for the em / italics decorator
      em: ({ children }) => <Em>{children}</Em>,
      strong: ({ children }) => <Strong>{children}</Strong>,
      code: ({ children }) => <Code>{children}</Code>,
      underline: ({ children }) => <Underline>{children}</Underline>,
      'strike-through': ({ children }) => (
        <StrikeThrough>{children}</StrikeThrough>
      ),

      // Ex. 2: rendering a custom `link` annotation
      externalLink: ({ children, value }) => {
        return (
          <Text
            cursor={'pointer'}
            textDecorationLine="underline"
            onPress={() => {
              Linking.canOpenURL(value.href).then(() =>
                Linking.openURL(value.href),
              )
            }}
          >
            {children}
          </Text>
        )
      },
    },
  }
}
